import React, { createContext, useContext } from "react";
import { Toast } from "primereact/components/toast/Toast";

interface ToastContextProps {
  toast: Toast;
}

export const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export function ToastProvider({ children, toast }: { children: React.ReactNode; toast: Toast }) {
  const memoizedToast = React.useMemo(() => ({ toast }), [toast]);

  return <ToastContext.Provider value={memoizedToast}>{children}</ToastContext.Provider>;
}
