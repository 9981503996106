import React, { useState, useEffect } from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./attributeGroupHeaderModal.scss";

export interface AttributHeaderData {
  flexTypePath: string;
  flextypeinternal: string;
  attributeGroupsData: {
    name: string;
    perms: any;
    bulkPermissions: any;
  };
}

interface PropInterface {
  isModalOpen: boolean;
  closeModal: () => void;
  addAttrPermissions: (attributHeaderData: any, perms: any, flextypePath: string) => void;
  attributHeaderData: AttributHeaderData;
}

export function AttributeGroupHeaderModal({ isModalOpen, closeModal, attributHeaderData, addAttrPermissions }: PropInterface) {
  const [modalTitle] = useState("Manage Permissions");
  const [otherOptionsEnabled] = useState(true);
  const [customPermissions, setCustomPermissions] = useState({
    creatableByAll: false,
    readableByAll: false,
    updatableByAll: false,
  });

  useEffect(() => {
    if (attributHeaderData.attributeGroupsData && attributHeaderData.attributeGroupsData.bulkPermissions) {
      setCustomPermissions({ ...attributHeaderData.attributeGroupsData.bulkPermissions });
    }
  }, [attributHeaderData]);

  const popupTitle = `${modalTitle}`;

  const addPermissions = () => {
    const perms = { ...customPermissions };
    addAttrPermissions(attributHeaderData, perms, attributHeaderData?.flexTypePath);
    closeModal();
  };

  const handlePermissionChange = (evt) => {
    const { name, checked } = evt.target;
    const permissions = { ...customPermissions };
    permissions[name] = checked;
    setCustomPermissions({ ...permissions });
  };

  return (
    <Dialog
      style={{ width: "50vw" }}
      closable={false}
      className="custom-modal"
      header={popupTitle}
      visible={isModalOpen}
      onHide={() => {
        isModalOpen = false;
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row headers mt-3">
              <div className="col-4">
                <h4>Flex Type:</h4>
              </div>
              <div className="col-8 second-col">
                <h4>{attributHeaderData?.flexTypePath}</h4>
              </div>
            </div>
            <div className="row headers mt-3">
              <div className="col-4">
                <h4>Attribute Group:</h4>
              </div>
              <div className="col-8 second-col">
                <h4>{attributHeaderData?.attributeGroupsData?.name}</h4>
              </div>
            </div>

            <div className="row sub-headers center-sub-div-text">
              <div className="col-2 grant-deny-title" />
              <div className="change-block center col-2 grant-deny-title">Grant</div>
            </div>

            <div className={`row row-data  ${otherOptionsEnabled ? "gray" : ""}`}>
              <div className="change-block col-3">
                <h4>Read For All</h4>
              </div>
              <div className="change-block center col-2">
                <input type="checkbox" className="rb" name="readableByAll" checked={customPermissions?.readableByAll} onChange={handlePermissionChange} />
              </div>
            </div>

            <div className={`row row-data  ${otherOptionsEnabled ? "gray" : ""}`}>
              <div className="change-block col-3">
                <h4>Create For All</h4>
              </div>
              <div className="change-block center col-2">
                <input type="checkbox" className="rb" name="creatableByAll" checked={customPermissions?.creatableByAll} onChange={handlePermissionChange} />
              </div>
            </div>

            <div className={`row row-data  ${otherOptionsEnabled ? "gray" : ""}`}>
              <div className="change-block col-3">
                <h4>Update For All</h4>
              </div>
              <div className="change-block center col-2">
                <input type="checkbox" className="rb" name="updatableByAll" checked={customPermissions?.updatableByAll} onChange={handlePermissionChange} />
              </div>
            </div>

            <div className="row hr-row">
              <div className="col-12" />
            </div>
          </div>
        </div>
        <div className="row mt-3 w-100">
          <div className="col-12">
            {/* {modalTitle === "Update" && (
              <Button className="p-button-success mr-2" type="button" onClick={updateRights}>
                Update
                <i className="pi pi-plus ms-1" />
              </Button>
            )} */}
            {/* {modalTitle === "Update" && (
              <Button className="p-button-danger mr-2" onClick={deleteRights}>
                Delete
                <i className="pi pi-trash ms-1" />
              </Button>
            )} */}
            {/* {modalTitle === "Add" && (
              <Button className="p-button-success mr-2" onClick={addPermissions}>
                Add
                <i className="pi pi-plus ms-1" />
              </Button>
            )} */}
            <Button className="p-button-success mr-2" onClick={addPermissions}>
              Add
              <i className="pi pi-plus ms-1" />
            </Button>
            <Button className="p-button-info mr-2" onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
