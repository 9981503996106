const urlsReplace = {};

export default urlsReplace;

export const urlFlexTypes = "pcx/v1/:pipeline/FlexTypes";
export const urlFlexTypesBase = "pcx/v1/:pipeline/FlexTypes?view=ROOT";
export const urlUserGroups = "pcx/v1/:pipeline/UserGroups";
export const urlUserGroupsOfFlexType = "pcx/v1/:pipeline/FlexTypes/:flexType/usergroups";
export const urlAcls = "pcx/v1/:pipeline/Acls";

export const pipeLinesGet = "pcx/v1/pipelines";
export const pipeLinesReplenish = "pcx/v1/processes/replenish_acl";
export const pipeLinesLogs = "pcx/v1/processes/:process/status";
export const pipeLinesDelete = "pcx/v1/pipelines";
export const attributeGrpsPerms = "pcx/v1/:pipeline/FlexTypes/attributegroups/permissions";
