/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { CustomControl } from "./crudPopoverInterface";
import "./crudPopover.scss";
import { CrudPermissionSet, CrudPermissions, GridRowCell, GridRowCellAdd, GridRowCellUpdate } from "../data-table/data-table-interfaces";

type PayloadFunction<T = GridRowCellAdd> = (payload: T) => void;

interface CrudPopOverProps {
  isModalOpen: boolean;
  modalCellData: GridRowCell;
  closeModal: () => void;
  addRightsHandle: PayloadFunction;
  updateRightsHandle: PayloadFunction<GridRowCellUpdate>;
  updateAgarRightsHandle: PayloadFunction<any>;
  addAgarRightsHandle: PayloadFunction<any>;
  deleteRightsHandle: PayloadFunction<GridRowCell>;
  isAgarRow: boolean;
  attributeGroup?: { id: string; name: string };
}

interface UserGroupHeaderProps {
  title?: string;
  isAgarRow: boolean;
  attributeGroupTitle?: string;
}

function UserGroupHeader({ title, isAgarRow, attributeGroupTitle }: UserGroupHeaderProps) {
  return (
    <>
      <div className="row headers mt-3">
        <div className="col-4">
          <h4>User Group:</h4>
        </div>
        <div className="col-8 second-col mb-2">
          <h4>{title}</h4>
        </div>
      </div>
      {isAgarRow && (
        <div className="row headers mt-3">
          <div className="col-4">
            <h4>Attribute Group:</h4>
          </div>
          <div className="col-8 second-col mb-2">
            <h4>{attributeGroupTitle}</h4>
          </div>
        </div>
      )}
      <div className="border-bottom" />
    </>
  );
}

export function CrudPopOver({ isAgarRow, isModalOpen, modalCellData, closeModal, addRightsHandle, updateRightsHandle, addAgarRightsHandle, updateAgarRightsHandle, deleteRightsHandle, attributeGroup }: CrudPopOverProps) {
  const [modalTitle, setModalTitle] = useState<string>("Add");
  const [otherOptionsEnabled, setOtherOptionsEnabled] = useState(true);
  const [customPermissions, setCustomPermissions] = useState<CustomControl>();

  const defaultCustomPermissions = (defaultValue = ""): CustomControl =>
    ({
      full: defaultValue,
      create: defaultValue,
      read: defaultValue,
      update: defaultValue,
      delete: defaultValue,
    } as CustomControl);

  useEffect(() => {
    if (isAgarRow && modalCellData?.aclid && modalCellData?.aclid?.length) {
      setModalTitle("Update");
      const perms: CustomControl = { full: "" };
      if (modalCellData?.agarPerms?.C) {
        perms.create = "S1";
      } else {
        perms.create = "S0";
      }
      if (modalCellData?.agarPerms?.R) {
        perms.read = "S1";
      } else {
        perms.read = "S0";
      }
      if (modalCellData?.agarPerms?.U) {
        perms.update = "S1";
      } else {
        perms.update = "S0";
      }
      setCustomPermissions(perms);
    } else if (isAgarRow && !modalCellData?.aclid?.length) {
      setModalTitle("Add");
      const perms: CustomControl = { full: "" };
      if (modalCellData?.agarPerms?.C) {
        perms.create = "S1";
      } else {
        perms.create = "S0";
      }
      if (modalCellData?.agarPerms?.R) {
        perms.read = "S1";
      } else {
        perms.read = "S0";
      }
      if (modalCellData?.agarPerms?.U) {
        perms.update = "S1";
      } else {
        perms.update = "S0";
      }
      setCustomPermissions(perms);
    } else if (modalCellData?.aclid && modalCellData?.aclid?.length) {
      setModalTitle("Update");
      let defaultPerms = defaultCustomPermissions("I");
      defaultPerms.full = "";
      setOtherOptionsEnabled(true);
      if (modalCellData?.selected) {
        const selectedVals: CrudPermissions = modalCellData.selected as CrudPermissions;
        if (selectedVals.C) {
          defaultPerms.create = "S1";
        }
        if (selectedVals.R) {
          defaultPerms.read = "S1";
        }
        if (selectedVals.U) {
          defaultPerms.update = "S1";
        }
        if (selectedVals.D) {
          defaultPerms.delete = "S1";
        }
        if (selectedVals.A) {
          defaultPerms = defaultCustomPermissions("");
          defaultPerms.full = "S1";
        }

        if (selectedVals.C === 0) {
          defaultPerms.create = "S0";
        }
        if (selectedVals.R === 0) {
          defaultPerms.read = "S0";
        }
        if (selectedVals.U === 0) {
          defaultPerms.update = "S0";
        }
        if (selectedVals.D === 0) {
          defaultPerms.delete = "S0";
        }
        if (selectedVals.A === 0) {
          defaultPerms = defaultCustomPermissions("");
          defaultPerms.full = "S0";
        }
      }
      if (modalCellData?.allexcept) {
        const selectedVals = modalCellData.allexcept as CrudPermissions;
        if (selectedVals.C) {
          defaultPerms.create = "X1";
        }
        if (selectedVals.R) {
          defaultPerms.read = "X1";
        }
        if (selectedVals.U) {
          defaultPerms.update = "X1";
        }
        if (selectedVals.D) {
          defaultPerms.delete = "X1";
        }
        if (selectedVals.A) {
          defaultPerms = defaultCustomPermissions("");
          defaultPerms.full = "X1";
        }

        if (selectedVals.C === 0) {
          defaultPerms.create = "X0";
        }
        if (selectedVals.R === 0) {
          defaultPerms.read = "X0";
        }
        if (selectedVals.U === 0) {
          defaultPerms.update = "X0";
        }
        if (selectedVals.D === 0) {
          defaultPerms.delete = "X0";
        }
        if (selectedVals.A === 0) {
          defaultPerms = defaultCustomPermissions("");
          defaultPerms.full = "X0";
        }
      }
      setCustomPermissions({ ...defaultPerms });
    } else {
      const defaultPerms = defaultCustomPermissions("I");
      defaultPerms.full = "";
      setOtherOptionsEnabled(true);
      setCustomPermissions({ ...defaultPerms });
      setModalTitle("Add");
    }
  }, [modalCellData]);

  const formatACLPermissions = () => {
    let permissionObj: CrudPermissionSet | null = {
      allexcept: {},
      selected: {},
    };

    if (customPermissions.read === "S1") {
      permissionObj.selected.R = 1;
    }
    if (customPermissions.create === "S1") {
      permissionObj.selected.C = 1;
    }
    if (customPermissions.update === "S1") {
      permissionObj.selected.U = 1;
    }
    if (customPermissions.delete === "S1") {
      permissionObj.selected.D = 1;
    }

    if (customPermissions.read === "S0") {
      permissionObj.selected.R = 0;
    }
    if (customPermissions.create === "S0") {
      permissionObj.selected.C = 0;
    }
    if (customPermissions.update === "S0") {
      permissionObj.selected.U = 0;
    }
    if (customPermissions.delete === "S0") {
      permissionObj.selected.D = 0;
    }

    if (customPermissions.read === "X1") {
      permissionObj.allexcept.R = 1;
    }
    if (customPermissions.create === "X1") {
      permissionObj.allexcept.C = 1;
    }
    if (customPermissions.update === "X1") {
      permissionObj.allexcept.U = 1;
    }
    if (customPermissions.delete === "X1") {
      permissionObj.allexcept.D = 1;
    }
    if (customPermissions.read === "X0") {
      permissionObj.allexcept.R = 0;
    }
    if (customPermissions.create === "X0") {
      permissionObj.allexcept.C = 0;
    }
    if (customPermissions.update === "X0") {
      permissionObj.allexcept.U = 0;
    }
    if (customPermissions.delete === "X0") {
      permissionObj.allexcept.D = 0;
    }

    if (customPermissions.full === "S1") {
      permissionObj.selected = { A: 1 };
    }

    if (customPermissions.full === "S0") {
      permissionObj.selected = { A: 0 };
    }

    if (customPermissions.full === "X1") {
      permissionObj.allexcept = { A: 1 };
    }

    if (customPermissions.full === "X0") {
      permissionObj.allexcept = { A: 0 };
    }

    if (customPermissions.full === "I") {
      permissionObj = null;
    }

    if (customPermissions.create === "I" && customPermissions.read === "I" && customPermissions.update === "I" && customPermissions.delete === "I") {
      permissionObj = null;
    }

    return permissionObj;
  };

  const selectRadio = (event) => {
    const { name, value } = event.target;
    if (name === "full") {
      const custControl = defaultCustomPermissions("");
      custControl.full = value;
      setCustomPermissions({ ...custControl });
      setOtherOptionsEnabled(false);
    } else {
      // check if switching out of full mode
      let custControl = { ...customPermissions };
      if (customPermissions.full !== "") {
        custControl = defaultCustomPermissions("I");
        custControl.full = "";
      }

      // set all other controls to Inherit, if not already set
      if (name === "create") {
        custControl.create = value;
      }

      if (name === "read") {
        custControl.read = value;
      }

      if (name === "update") {
        custControl.update = value;
      }

      if (name === "delete") {
        custControl.delete = value;
      }
      setOtherOptionsEnabled(true);
      setCustomPermissions({ ...custControl });
    }

    // setOtherOptionsEnabled(customPermissions.full !== '');
  };

  const addRights = () => {
    const cellValue = formatACLPermissions();
    closeModal();
    if (cellValue) {
      const payload: GridRowCellAdd = {
        allexcept: cellValue.allexcept,
        flextypepath: modalCellData.flextypepath,
        selected: cellValue.selected,
        usergroupname: modalCellData.usergroupname,
      };
      addRightsHandle(payload);
    }
  };

  const updateRights = async () => {
    const payload: GridRowCellUpdate = {
      ...modalCellData,
      update: formatACLPermissions(),
    };
    closeModal();
    updateRightsHandle(payload);
  };

  const formatAgarPermissions = () => {
    const perms: CrudPermissions = {};
    if (customPermissions.read === "S1") {
      perms.R = 1;
    }
    if (customPermissions.create === "S1") {
      perms.C = 1;
    }
    if (customPermissions.update === "S1") {
      perms.U = 1;
    }

    return perms;
  };

  const addAgarRights = async () => {
    const payload = {
      usergroupname: modalCellData?.usergroupname,
      flextypepath: modalCellData.flextypepath,
      agar: [
        {
          name: attributeGroup.name,
          id: attributeGroup.id,
          permissions: formatAgarPermissions(),
        },
      ],
    };
    closeModal();
    addAgarRightsHandle(payload);
  };

  const updateAgarRights = async () => {
    const payload = {
      aclid: modalCellData.aclid,
      current: modalCellData.agarPerms || [],
      name: attributeGroup.name,
      id: attributeGroup.id,
      usergroupname: modalCellData?.usergroupname,
      flextypepath: modalCellData.flextypepath,
      updated: formatAgarPermissions(),
    };
    closeModal();
    updateAgarRightsHandle(payload);
  };

  const deleteRights = async () => {
    const payload: GridRowCell = {
      ...modalCellData,
    };
    closeModal();
    deleteRightsHandle(payload);
  };

  const popupTitle = `${modalTitle} ${isAgarRow ? "AGAR" : "ACL"}`;

  const checkAboutBulkPermission = (permissionKey: string): boolean => {
    return !!(modalCellData && modalCellData.bulkPermissions && modalCellData.bulkPermissions[permissionKey]);
  };

  return (
    <Dialog
      style={{ width: "50vw" }}
      closable={false}
      className="custom-modal"
      header={popupTitle}
      visible={isModalOpen}
      onHide={() => {
        isModalOpen = false;
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row headers mt-3">
              <div className="col-4">
                <h4>Flex Type:</h4>
              </div>
              <div className="col-8 second-col">
                <h4>{modalCellData?.flextypepath}</h4>
              </div>
            </div>
            <UserGroupHeader isAgarRow={isAgarRow} title={modalCellData?.usergroupname} attributeGroupTitle={attributeGroup?.name} />
            {!isAgarRow && (
              <div className="row headers">
                <div className="col-2">
                  <h4> </h4>
                </div>
                <div className="col-4 second-col">
                  <h4>Selected Participants</h4>
                </div>
                <div className="col-4 center">
                  <h4>All Except Participants</h4>
                </div>
                <div className="col-2 center">
                  <h4>Inherit</h4>
                </div>
              </div>
            )}

            <div className="row sub-headers center-sub-div-text">
              {checkAboutBulkPermission("readableByAll") && isAgarRow && (
                <div className="row row-data gray">
                  <div className="change-block col-2">
                    <h4>Read</h4>
                  </div>
                  <div className="change-block center col-7">Enabled at attribute level</div>
                </div>
              )}
              {checkAboutBulkPermission("creatableByAll") && isAgarRow && (
                <div className="row row-data gray">
                  <div className="change-block col-2">
                    <h4>Create</h4>
                  </div>
                  <div className="change-block center col-7">Enabled at attribute level</div>
                </div>
              )}
              {checkAboutBulkPermission("updatableByAll") && isAgarRow && (
                <div className="row row-data gray">
                  <div className="change-block col-2">
                    <h4>Update</h4>
                  </div>
                  <div className="change-block center col-7">Enabled at attribute level</div>
                </div>
              )}

              {!(checkAboutBulkPermission("updatableByAll") && checkAboutBulkPermission("creatableByAll") && checkAboutBulkPermission("readableByAll")) && (
                <>
                  <div className="col-2 grant-deny-title" />
                  <div className="change-block center col-2 grant-deny-title">Grant</div>
                  <div className="change-block center col-2 grant-deny-title">Inherit</div>
                </>
              )}
              {!isAgarRow && (
                <>
                  <div className="change-block center col-2 grant-deny-title">Grant</div>
                  <div className="change-block center col-2 grant-deny-title">Inherit</div>
                  <div className="change-block center col-2 grant-deny-title" />
                </>
              )}
            </div>

            {!checkAboutBulkPermission("readableByAll") && (
              <div className={`row row-data  ${otherOptionsEnabled ? "gray" : ""}`}>
                <div className="change-block col-2">
                  <h4>Read</h4>
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S1" name="read" checked={customPermissions?.read === "S1"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S0" name="read" checked={customPermissions?.read === "S0"} onChange={selectRadio} />
                </div>

                {!isAgarRow && (
                  <>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="X1" name="read" checked={customPermissions?.read === "X1"} onChange={selectRadio} />
                    </div>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="X0" name="read" checked={customPermissions?.read === "X0"} onChange={selectRadio} />
                    </div>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="I" name="read" checked={customPermissions?.read === "I"} onChange={selectRadio} />
                    </div>
                  </>
                )}
              </div>
            )}

            {!checkAboutBulkPermission("creatableByAll") && (
              <div className={`row row-data  ${otherOptionsEnabled ? "gray" : ""}`}>
                <div className="change-block col-2">
                  <h4>Create</h4>
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S1" name="create" checked={customPermissions?.create === "S1"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S0" name="create" checked={customPermissions?.create === "S0"} onChange={selectRadio} />
                </div>

                {!isAgarRow && (
                  <>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="X1" name="create" checked={customPermissions?.create === "X1"} onChange={selectRadio} />
                    </div>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="X0" name="create" checked={customPermissions?.create === "X0"} onChange={selectRadio} />
                    </div>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="I" name="create" checked={customPermissions?.create === "I"} onChange={selectRadio} />
                    </div>
                  </>
                )}
              </div>
            )}

            {!checkAboutBulkPermission("updatableByAll") && (
              <div className={`row row-data  ${otherOptionsEnabled ? "gray" : ""}`}>
                <div className="change-block col-2">
                  <h4>Update</h4>
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S1" name="update" checked={customPermissions?.update === "S1"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S0" name="update" checked={customPermissions?.update === "S0"} onChange={selectRadio} />
                </div>
                {!isAgarRow && (
                  <>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="X1" name="update" checked={customPermissions?.update === "X1"} onChange={selectRadio} />
                    </div>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="X0" name="update" checked={customPermissions?.update === "X0"} onChange={selectRadio} />
                    </div>
                    <div className="change-block center col-2">
                      <input type="radio" className="rb" value="I" name="update" checked={customPermissions?.update === "I"} onChange={selectRadio} />
                    </div>
                  </>
                )}
              </div>
            )}

            {!isAgarRow && (
              <div className={`row row-data  ${otherOptionsEnabled ? "gray" : ""}`}>
                <div className="change-block col-2">
                  <h4>Delete</h4>
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S1" name="delete" checked={customPermissions?.delete === "S1"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S0" name="delete" checked={customPermissions?.delete === "S0"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="X1" name="delete" checked={customPermissions?.delete === "X1"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="X0" name="delete" checked={customPermissions?.delete === "X0"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="I" name="delete" checked={customPermissions?.delete === "I"} onChange={selectRadio} />
                </div>
              </div>
            )}

            <div className="row hr-row">
              <div className="col-12" />
            </div>

            {!isAgarRow && (
              <div className={`row row-data  ${!otherOptionsEnabled ? "gray" : ""}`}>
                <div className="change-block col-2">
                  <h4>Full Control</h4>
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S1" name="full" checked={customPermissions?.full === "S1"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="S0" name="full" checked={customPermissions?.full === "S0"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="X1" name="full" checked={customPermissions?.full === "X1"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="X0" name="full" checked={customPermissions?.full === "X0"} onChange={selectRadio} />
                </div>
                <div className="change-block center col-2">
                  <input type="radio" className="rb" value="I" name="full" checked={customPermissions?.full === "I"} onChange={selectRadio} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mt-3 w-100">
          <div className="col-12">
            {/* AGAR -  */}
            {modalTitle === "Add" && isAgarRow && (
              <Button className="p-button-success mr-2" type="button" onClick={addAgarRights} disabled={checkAboutBulkPermission("updatableByAll") && checkAboutBulkPermission("creatableByAll") && checkAboutBulkPermission("readableByAll")}>
                Add
                <i className="pi pi-plus ms-1" />
              </Button>
            )}
            {modalTitle === "Update" && isAgarRow && (
              <Button className="p-button-success mr-2" type="button" onClick={updateAgarRights} disabled={checkAboutBulkPermission("updatableByAll") && checkAboutBulkPermission("creatableByAll") && checkAboutBulkPermission("readableByAll")}>
                Update
                <i className="pi pi-plus ms-1" />
              </Button>
            )}

            {/* ACL - */}
            {modalTitle === "Add" && !isAgarRow && (
              <Button className="p-button-success mr-2" onClick={addRights}>
                Add
                <i className="pi pi-plus ms-1" />
              </Button>
            )}
            {modalTitle === "Update" && !isAgarRow && (
              <Button className="p-button-success mr-2" type="button" onClick={updateRights}>
                Update
                <i className="pi pi-plus ms-1" />
              </Button>
            )}
            {modalTitle === "Update" && !isAgarRow && (
              <Button className="p-button-danger mr-2" onClick={deleteRights}>
                Delete
                <i className="pi pi-trash ms-1" />
              </Button>
            )}

            <Button className="p-button-info mr-2" onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
