/* eslint-disable import/no-cycle, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, camelcase, no-use-before-define, @typescript-eslint/restrict-template-expressions, no-console, max-len, consistent-return, import/prefer-default-export, no-unreachable, no-alert, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, import/no-unresolved, import/no-unresolved, import/extensions, no-param-reassign, @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */

import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { obsEnvName } from "src/services/observables";
import { GridRow } from "src/components/data-table/data-table-interfaces";
import { useOkta } from "../constants";

export const switchEnvName = (envName: string) => {
  obsEnvName.next(envName);
};

export function fetchEmail(): string {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [getUserEmail, setUserEmail] = useState<string>("test@localhost");

  if (useOkta) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { authState, oktaAuth } = useOktaAuth();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [, setUserInfo] = useState<UserClaims<CustomUserClaims>>(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
      } else {
        oktaAuth
          .getUser()
          .then((info) => {
            if (info && info.email) {
              setUserInfo(info);
              setUserEmail(info.email);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }, [authState, oktaAuth]); // Update if authState changes
  }
  return getUserEmail;
}

export const replaceUrlPipeline = (url: string, envName: string): string => {
  return url.replace(":pipeline", envName === "shortcycle" ? "sc" : envName);
};

export const replaceUrlProcess = (url: string, process: string): string => {
  return url.replace(":process", process);
};

export const sortGridRows = (a: GridRow, b: GridRow) => {
  const aSplit = a.flexObject.split("/");
  const bSplit = b.flexObject.split("/");

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.max(aSplit.length, bSplit.length); i++) {
    if (!aSplit[i]) return -1; // If aSplit ends before bSplit, a comes first
    if (!bSplit[i]) return 1; // If bSplit ends before aSplit, b comes first

    if (aSplit[i] !== bSplit[i]) {
      return aSplit[i].localeCompare(bSplit[i]);
    }
  }

  if (a.isAGAR) {
    // is string are equal and any of the row is AGAR it comes first
    return 1;
  } else if (b.isAGAR) {
    return -1;
  }

  return 0; // If both strings are equal or one string is a substring of the other
};
