import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export default function AuthRequiredModal({ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }) {
  const closeModal = () => {
    setAuthRequiredModalOpen(false);
  };

  const confirmModal = () => {
    setAuthRequiredModalOpen(false);
    triggerLogin();
  };

  useEffect(() => {
    if (authRequiredModalOpen) {
      triggerLogin();
    }

  }, [authRequiredModalOpen]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <Dialog
      onClose={closeModal}
      onActionClick={confirmModal}
      open={authRequiredModalOpen}
      showCloseIcon={false}
      showDimmer={false}
      visible={isModalOpen}
      onHide={() => {
        setIsModalOpen(false);
      }}
    >
      <h1>Auth required</h1>
      <h3>Do you want to re-authenticate?</h3>

      <Button onClick={closeModal}>
        No
      </Button>
      <Button positive onClick={confirmModal}>
        Yes
      </Button>
    </Dialog>
  );
}
