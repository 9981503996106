import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ActionLogProps } from "./ActionLogsInterface";
import "./action-logs.scss";

export function ActionLogs(props: ActionLogProps) {
  const { logs, loading } = props;
  return (
    <DataTable value={logs} scrollable scrollHeight="200px" loading={loading}>
      <Column field="executionKey" header="Execution Key" />
      <Column field="createdAt" header="When" />
      <Column field="username" header="Who" />
      <Column field="process" header="Process" />
      <Column field="step" header="Step" />
      <Column field="status" header="Status" />
    </DataTable>
  );
}
