import React from 'react';
import { Dialog } from 'primereact/dialog';
import { oktaUrl } from '../constants';

export default function CorsErrorModal({ corsErrorModalOpen, setCorsErrorModalOpen }) {
  const baseUrl = oktaUrl.split('/oauth2')[0];
  const hostParts = new URL(baseUrl).host.split('.');
  hostParts[0] += '-admin';
  const adminHost = hostParts.join('.');
  const corsAdminUrl = `https://${adminHost}/admin/access/api/trusted_origins`;

  // URL to guide for enabling CORS
  const guideUrl = 'https://developer.okta.com/docs/guides/enable-cors/granting-cors/';

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // CORS error modal
  return (
    <Dialog
      onClose={() => setCorsErrorModalOpen(false)}
      open={corsErrorModalOpen}
      visible={isModalOpen}
      onHide={() => {
        setIsModalOpen(false);
      }}
    >
      <h1>Network Error</h1>
      <p>Seems like logout API call resulted with CORS error.</p>
      <p>
        You may need to add your origin
        {' '}
        {window.location.origin}
        {' '}
        to list of trusted origins in your
        {' '}
        <a href={corsAdminUrl} target="_blank" rel="noreferrer">Okta Administrator Dashboard</a>
      </p>
      <p>
        Read
        {' '}
        <a href={guideUrl} target="_blank" rel="noreferrer">this guide</a>
        {' '}
        for more info.
      </p>
    </Dialog>
  );
}
