import { createAction, createReducer } from "@reduxjs/toolkit";
import { Environment } from "src/components/env-list/envListInterface";
import { RootState } from ".";

export const actionCreators = {
  setObsPipeLines: createAction<{ pipelines: Environment[] }, "SET_PIPELINES">("SET_PIPELINES"),
  setObsPipeLinesLogs: createAction<{ pipelinesLogs: any[] }, "SET_PIPELINES_LOGS">("SET_PIPELINES_LOGS"),
  setObsEnvName: createAction<{ envName: null | string }, "SET_ENV_NAME">("SET_ENV_NAME"),
};

interface ReducerState {
  obsPipeLines: Environment[];
  obsPipeLinesLogs: any[];
  obsEnvName: null | string;
}

const initialState: ReducerState = {
  obsPipeLines: [],
  obsPipeLinesLogs: [],
  obsEnvName: null,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actionCreators.setObsPipeLines, (state, action) => {
    state.obsPipeLines = action.payload.pipelines;
  });

  builder.addCase(actionCreators.setObsPipeLinesLogs, (state, action) => {
    state.obsPipeLinesLogs = action.payload.pipelinesLogs;
  });

  builder.addCase(actionCreators.setObsEnvName, (state, action) => {
    state.obsEnvName = action.payload.envName;
  });
});

export const selectPipelines = (state: RootState) => state.pipelineApi.obsPipeLines;
