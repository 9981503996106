/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions, react/jsx-indent, no-template-curly-in-string, react/no-array-index-key, brace-style, import/order, react/jsx-no-useless-fragment */

import React from "react";
import classNames from "classnames";
import { deleteUrl, postUrl } from "src/util/axios-rest";

import { pipeLinesReplenish, pipeLinesDelete } from "src/util/constants";
import "./pipelineCard.scss";
import { useToast } from "src/util/Toast";
import { PipelineCardProps, ActionButtonProps, RefreshActions } from "./pipelineCardInterface";
import { ActionLogs } from "../action-logs/ActionLogs";

function ActionButton(props: ActionButtonProps) {
  const { onClick, children, variant = "warning" } = props;

  const className = { "btn-warning": variant === "warning", "btn-danger": variant === "danger" };

  return (
    <button type="button" onClick={onClick} className={classNames("btn m-2", className)}>
      {children}
    </button>
  );
}

export function PipelineCard(props: PipelineCardProps) {
  const { environment, fetchLogsData, logsData } = props;
  const { pipeline } = environment;

  const { toast } = useToast();

  const onRefresh = async (actionType: RefreshActions) => {
    let toastMessage = `Started Refresh ${actionType} on Pipeline ${environment.pipeline}`;

    toast.show({ severity: "info", summary: "Info", detail: toastMessage, life: 3000 });

    let replenish = {
      userGroups: false,
      flexTypes: false,
      ACLs: false,
    };

    if (actionType === "all") {
      replenish = {
        userGroups: true,
        flexTypes: true,
        ACLs: true,
      };
    } else {
      replenish[actionType] = true;
    }

    // const pipeline: string = env.pipeline.split(":")[1];
    toastMessage = `Refresh ${actionType} completed on Pipeline ${environment.pipeline}`;

    const payloadObj = {
      pipeline,
      replenish,
    };

    try {
      await postUrl(pipeLinesReplenish, payloadObj);

      toast.show({ severity: "success", summary: "Success", detail: toastMessage, life: 3000 });
    } catch (err) {
      console.error(err);
    }

    const logType = actionType === "all" ? "ALL" : "REPLENISH_ACL";

    fetchLogsData(logType);
  };

  const onDelete = async () => {
    toast.show({ severity: "info", summary: "Info", detail: `Deleting Pipeline ${pipeline}`, life: 3000 });

    const pipeLinesDeleteUrl = `${pipeLinesDelete}/${pipeline}`;

    const deleteAll = {
      userGroups: true,
      flexTypes: true,
      ACLs: true,
    };

    try {
      await deleteUrl(pipeLinesDeleteUrl, deleteAll);
      toast.show({ severity: "success", summary: "Success", detail: `Pipeline ${pipeline} Deleted`, life: 3000 });
    } catch (err) {
      console.error(err);
    }

    fetchLogsData("DELETE_DATA");
  };

  return (
    <div className="row p-3 border-secondary border-bottom">
      <div className="d-flex justify-content-center align-items-center">
        <h2 className="text-capitalize">{environment.displayText}</h2>
        {!environment.isActive && <span className="p-tag p-tag-rounded p-tag-danger inactive-badge m-2">Inactive</span>}
      </div>

      <div className="row">
        <div className="d-flex justify-content-center">
          <ActionButton onClick={() => onRefresh("userGroups")}>Refresh Usergroups</ActionButton>
          <ActionButton onClick={() => onRefresh("flexTypes")}>Refresh Flextypes</ActionButton>
          <ActionButton onClick={() => onRefresh("ACLs")}>Refresh ACLs</ActionButton>
          <ActionButton onClick={() => onRefresh("all")}>Refresh all</ActionButton>
          <ActionButton onClick={onDelete} variant="danger">{`Delete ${environment.displayText}`}</ActionButton>
        </div>
      </div>
      <div className="row mt-4">
        <div className="d-flex flex-column align-items-center">
          <ActionLogs loading={logsData.loading} logs={logsData.logs} />
        </div>
      </div>
    </div>
  );
}
