/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressSpinner } from "primereact/progressspinner";
import { urlAcls, urlFlexTypes, urlFlexTypesBase, urlUserGroups, urlUserGroupsOfFlexType } from "src/util/constants";
import { axiosInstance } from "src/services/axios";
import { obsEnvName, obsOktaToken } from "src/services/observables";
import { replaceUrlPipeline } from "../../util/functions";
import { FlexTypes } from "../flex-type/FlexType";
import { UserGroup } from "../user-groups/userGroupsInterface";
import { FlexBase } from "../flex-type/flexTypeInterface";
import "./main.scss";
import { UserGroups } from "../user-groups/UserGroups";
import { MainDataTable } from "../data-table/data-table";
import { AppBar } from "../AppBar";
import { AgarObject, FlexObjectAPIReturn, GridRow, GridRowCell } from "../data-table/data-table-interfaces";

const legend = [
  { key: "S", value: "Selected Participants" },
  { key: "X", value: "All Except Participants" },
  { key: "+", value: "Has been granted permission" },
  { key: "-", value: "Has permission removed" },
  { key: "FULL", value: "Full permissions" },
  { key: "C", value: "Create" },
  { key: "R", value: "Read" },
  { key: "U", value: "Update" },
  { key: "D", value: "Delete" },
];

export function Main() {
  const [currentEnvName, setCurrentEnvName] = useState(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  // Loader
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // check if all flexePaths loaded
  const [isFlexPathsLoaded, setIsFlexPathsLoaded] = useState<boolean>(false);
  const [selectedFlexBase, setSelectedFlexBase] = useState<string>(null);
  // Flex Types
  const [flexBases, setFlexBases] = useState<FlexBase[]>([]);
  const [getAllFlexPaths, setAllFlexPaths] = useState<FlexObjectAPIReturn[]>([]);
  const [attributeGroups, setAttributeGroups] = useState<Record<string, AgarObject[]>>();
  // User Groups
  const [allUserGroups, setAllUserGroups] = useState<UserGroup[]>([]);
  const [userGroupsSelected, setUserGroupsSelected] = useState<UserGroup[]>([]);
  // TriState checkBox setTriStateVal
  const [triStateVal, setTriStateVal] = useState<boolean | null>(false);
  // For Auto Select User Group
  const [isAutoSelectUG, setIsAutoSelectUG] = useState<boolean>(true);
  // For Show Attribute Groups
  const [showAttributeGroupsCheckbox, setShowAttributeGroupsCheckbox] = useState<boolean>(true);
  // Data Table setTableData
  const [gridRows, setGridRows] = useState<GridRow[]>([]);
  const mounted = useRef(false);

  const fetchFlexTypesBases = async () => {
    const url = replaceUrlPipeline(urlFlexTypesBase, currentEnvName);
    const getFlexes = await axiosInstance.get(url);
    const flexPathsBase = getFlexes.data.data;

    const flexBasesWithCheckbox = flexPathsBase
      .map((flexBase: FlexObjectAPIReturn) => flexBase.flexobject)
      .map(
        (flexBase: Partial<FlexBase>) =>
        ({
          flexobject: flexBase,
          selected: false,
        } as FlexBase),
      )
      .sort((a: FlexBase, b: FlexBase) => (a.flexobject > b.flexobject ? 1 : -1));
    if (mounted.current) {
      setFlexBases(flexBasesWithCheckbox);
    }
  };

  const fetchFlexTypesAll = async () => {
    const url = replaceUrlPipeline(urlFlexTypes, currentEnvName);

    const getFlexes = await axiosInstance.get(url);
    const allFlexPaths = getFlexes.data.data;

    if (mounted.current) {
      setAttributeGroups(() => {
        const newAttributeGroup = {};
        allFlexPaths.forEach((flexPath) => {
          const attributeGroup = flexPath.attributeGroups;

          const flexObject = flexPath.flextypepath;

          if (!(flexObject in newAttributeGroup)) {
            newAttributeGroup[flexObject] = attributeGroup;
          }
        });
        return newAttributeGroup;
      });
      setAllFlexPaths(allFlexPaths);
    }

    setIsFlexPathsLoaded(true);
  };

  const fetchUserGroups = async () => {
    try {
      const url = replaceUrlPipeline(urlUserGroups, currentEnvName);
      const getUserTypes = await axiosInstance.get(url);
      let getResData = getUserTypes.data.data;
      getResData = getResData.map(
        (userGroup: Partial<UserGroup>) =>
        ({
          ...userGroup,
          checked: false,
        } as UserGroup),
      );
      if (mounted.current) {
        setAllUserGroups([...getResData]);
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    let tokenSubscription;
    mounted.current = true;
    if (currentEnvName) {
      tokenSubscription = obsOktaToken.subscribe(async (bearerToken) => {
        if (bearerToken) {
          await fetchUserGroups();
          await fetchFlexTypesBases();
          setIsLoading(false);
          await fetchFlexTypesAll();
        }
      });
    }
    return () => {
      mounted.current = false;
      if (tokenSubscription) {
        tokenSubscription.unsubscribe();
      }
    };
  }, [currentEnvName]);

  useEffect(() => {
    const envName = obsEnvName.subscribe((env) => {
      setCurrentEnvName(env);
    });
    return () => {
      if (envName) {
        envName.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (triStateVal) {
      const allChecked = allUserGroups.map((ug: UserGroup) => ({ ...ug, checked: true }));
      setAllUserGroups([...allChecked]);
    }
    if (triStateVal === null) {
      const allUnChecked = allUserGroups.map((ug: UserGroup) => ({ ...ug, checked: false }));
      setAllUserGroups([...allUnChecked]);
    }
  }, [triStateVal]);

  useEffect(() => {
    if (triStateVal) {
      const allChecked = allUserGroups.map((ug: UserGroup) => ({ ...ug, checked: true }));
      setAllUserGroups([...allChecked]);
    }
    if (triStateVal === null) {
      const allUnChecked = allUserGroups.map((ug: UserGroup) => ({ ...ug, checked: false }));
      setAllUserGroups([...allUnChecked]);
    }
  }, [setAllUserGroups]);

  const fetchAllACLs = async (ugs: UserGroup[]) => {
    // if (triStateVal) {
    const selectedFlexes = [...flexBases].filter((flex: FlexBase) => flex.selected);
    const selectedUserGroups = [...ugs].filter((ug: UserGroup) => ug.checked);
    setUserGroupsSelected([...selectedUserGroups]);
    // const allGridRows: GridRow[] = [];
    const allGridRows: any[] = [];

    // iterate over each flex base and fetch
    for await (const flex of selectedFlexes) {
      const flexBase = flex.flexobject;
      const selectedUserGroupsName = selectedUserGroups.map((ug: UserGroup) => ug.name);
      const url = replaceUrlPipeline(urlAcls, currentEnvName);
      const Uri = `${url}?FlexTypes=${flexBase}&UserGroups=${selectedUserGroupsName.join(",")}`;
      const fetchAcls = await axiosInstance.get(Uri);
      const gridRowCellsMixedBases: GridRowCell[] = fetchAcls.data.data;
      // get the list of rows
      const uniqueFlextypePaths: string[] = getAllFlexPaths.filter((flexObjectAPIReturn: FlexObjectAPIReturn) => flex.flexobject === flexObjectAPIReturn.flexobject).map((flexObjectAPIReturn: FlexObjectAPIReturn) => flexObjectAPIReturn.flextypepath);
      uniqueFlextypePaths.forEach((flextypePath: string) => {
        const gridRow: GridRow = {
          display: true,
          flexObject: flextypePath,
          cells: [],
          flexBase: flex.flexobject,
          flextypeinternal: "",
        };

        // api will return empty cells if we have no data
        // empty cells will not have an aclid
        gridRow.cells = gridRowCellsMixedBases.filter((gridRowCell: GridRowCell) => gridRowCell.flextypepath === flextypePath && Object.hasOwn(gridRowCell, "aclid"));

        if (gridRow.cells.length) {
          gridRow.flextypeinternal = gridRow.cells[0].flextypeinternal;
        }
        allGridRows.push({ ...gridRow });

        const uniqueAgarRows = [];
        gridRow.cells.forEach((cell: GridRowCell) => {
          gridRow.cells = [];
          if (cell.agar) {
            gridRow.isAGAR = true;
            const allAttributeGroups = attributeGroups[gridRow.flexObject] || [];
            cell.agar.forEach((agarCell) => {
              const agarRowObject = uniqueAgarRows.find((row) => row.attributeGroupsData.id === agarCell.id);
              const currentAttributeGroups = allAttributeGroups.find((AG) => AG.name === agarCell.name);
              const newCell = {
                usergroupname: cell.usergroupname,
                flextypePath,
                id: agarCell.id,
                aclid: cell.aclid,
                flextypeinternal: cell.flextypeinternal,
                agarPerms: { ...agarCell.permissions },
              };

              if (agarRowObject) {
                if (currentAttributeGroups) {
                  agarRowObject.attributeGroupsData = { ...currentAttributeGroups };
                }
                agarRowObject.cells.push(newCell);
              } else {
                const obj = {
                  display: true,
                  flexObject: flextypePath,
                  flexBase: flex.flexobject,
                  isAGAR: true,
                  flextypeinternal: cell.flextypeinternal,
                  attributeGroupsData: {
                    name: agarCell.name,
                    id: agarCell.id,
                    bulkPermissions: currentAttributeGroups ? currentAttributeGroups.bulkPermissions : {},
                  },
                  cells: [newCell],
                };
                uniqueAgarRows.push(obj);
              }
            });
          }
        });
        uniqueAgarRows.forEach((row) => {
          allGridRows.push(row);
        });
      });
    }
    const finalTableData = allGridRows.sort((a, b) => (a.flexObject > b.flexObject ? 1 : -1));
    setGridRows([...finalTableData]);
    setIsLoading(false);
  };

  // const addUserGroups = async (ugs: UserGroup[]) => {
  //   // first add to UG selected array
  //   setUserGroupsSelected(allUserGroups.filter((ug: UserGroup) => ug.checked === true));

  //   // concat the usergroups
  //   const userGroupsSelectedString: string = ugs.map((ug: UserGroup) => ug.name).join(",");

  //   // now do table functions
  //   const selectedFlexes = [...flexBases].filter((flex: FlexBase) => flex.selected);
  //   const flexBase: string[] = selectedFlexes.map((flex: FlexBase) => flex.flexobject);
  //   const flexBaseString: string = flexBase.join(",");
  //   if (!flexBaseString) return;

  //   const url = replaceUrlPipeline(urlAcls, currentEnvName);
  //   const uri = `${url}?FlexTypes=${flexBaseString}&UserGroups=${userGroupsSelectedString.replaceAll("&", "%26")}`;
  //   const fetchAcls = await axiosInstance.get(uri);

  //   const fetchedGridRowCells: GridRowCell[] = fetchAcls.data.data;

  //   // iterate over all rows
  //   setGridRows((globalGridRows: GridRow[]) => {
  //     globalGridRows = globalGridRows.map((gridRow: GridRow) => {
  //       // add new cell

  //       const matchingCells = fetchedGridRowCells.filter((gridRowCell: GridRowCell) => gridRowCell.flextypepath === gridRow.flexObject);

  //       if (matchingCells.length === 0) {
  //         console.error("No matching row", gridRow.flexObject);
  //       } else if (matchingCells.length > 1) {
  //         console.error("Multiple matching rows", gridRow.flexObject);
  //       } else {
  //         gridRow.cells.push(matchingCells[0]);
  //       }
  //       return gridRow;
  //     });

  //     // sort
  //     globalGridRows = globalGridRows.sort((a, b) => (a.flexObject > b.flexObject ? 1 : -1));

  //     return globalGridRows;
  //   });
  // };

  // const addUserGroup = async (ugs: UserGroup) => {
  //   if (!ugs) return;

  //   // first add to UG selected array
  //   setUserGroupsSelected(allUserGroups.filter((ug: UserGroup) => ug.checked === true));

  //   // now do table functions
  //   const selectedFlexes = [...flexBases].filter((flex: FlexBase) => flex.selected);
  //   const flexBase: string[] = selectedFlexes.map((flex: FlexBase) => flex.flexobject);
  //   const flexBaseString: string = flexBase.join(",");
  //   if (!flexBaseString) return;

  //   const url = replaceUrlPipeline(urlAcls, currentEnvName);
  //   const uri = `${url}?FlexTypes=${flexBaseString}&UserGroups=${ugs.name.replaceAll("&", "%26")}`;
  //   const fetchAcls = await axiosInstance.get(uri);

  //   const fetchedGridRowCells: GridRowCell[] = fetchAcls.data.data;

  //   // iterate over all rows
  //   setGridRows((globalGridRows: GridRow[]) => {
  //     globalGridRows = globalGridRows.map((gridRow: GridRow) => {
  //       // add new cell

  //       const matchingCells = fetchedGridRowCells.filter((gridRowCell: GridRowCell) => gridRowCell.flextypepath === gridRow.flexObject);

  //       if (matchingCells.length === 0) {
  //         // console.error("No match", gridRow);
  //       } else if (matchingCells.length > 1) {
  //         // console.error("Multiple matches", gridRow);
  //       } else {
  //         gridRow.cells.push(matchingCells[0]);
  //       }
  //       return gridRow;
  //     });

  //     // sort
  //     globalGridRows = globalGridRows.sort(sortGridRows);

  //     return globalGridRows;
  //   });
  // };

  // const loadFlexType = async (flextypeBase: string) => {
  //   let getUGs: UserGroup[];
  //   if (isAutoSelectUG) {
  //     const url = replaceUrlPipeline(urlUserGroupsOfFlexType, currentEnvName);
  //     const uri = url.replace(":flexType", flextypeBase);
  //     const getResp = await axiosInstance.get(uri);
  //     getUGs = getResp.data.data.userGroups;

  //     const existingUGs = allUserGroups.map((ug: UserGroup) => {
  //       const checkIfUGchecked = getUGs.some((existingUg: UserGroup) => existingUg.id === ug.id);
  //       if (!ug.checked) {
  //         ug.checked = checkIfUGchecked;
  //       }
  //       return ug;
  //     });

  //     setTimeout(() => {
  //       // first add to UG selected array
  //       setUserGroupsSelected(existingUGs.filter((ug: UserGroup) => ug.checked === true));
  //     }, 0);

  //     // set the usergroups on the checklist
  //     setAllUserGroups(existingUGs);
  //   }

  //   let userGroupsSelectedString: string;
  //   if (getUGs) {
  //     userGroupsSelectedString = getUGs.map((ug: UserGroup) => ug.name).join(",");
  //   } else {
  //     userGroupsSelectedString = userGroupsSelected.map((ug: UserGroup) => ug.name).join(",");
  //   }

  //   // we can actually add rows without adding UGs

  //   const url = replaceUrlPipeline(urlAcls, currentEnvName);
  //   const Uri = `${url}?FlexTypes=${flextypeBase}&UserGroups=${userGroupsSelectedString.replaceAll("&", "%26")}`;
  //   const fetchAcls = await axiosInstance.get(Uri);

  //   const fetchedGridRowCells: GridRowCell[] = fetchAcls.data.data;

  //   const uniqueFlextypePaths: string[] = getAllFlexPaths.filter((flexObjectAPIReturn: FlexObjectAPIReturn) => flexObjectAPIReturn.flexobject === flextypeBase).map((flexObjectAPIReturn: FlexObjectAPIReturn) => flexObjectAPIReturn.flextypepath);

  //   let globalGridRows = [...gridRows];
  //   // iterate all unique Flextype Paths - each will be a grid row
  //   // they either exist already, or need to be created
  //   uniqueFlextypePaths.forEach((flextypePath: string) => {
  //     // start with blank placeholder grid row
  //     let currentGridRow: GridRow = { display: true, flexBase: flextypeBase, flexObject: flextypePath, cells: [] };
  //     let needToAdd = true; // need to track if we need to add this row to globalGridRows

  //     // run filter to see if we already have this row
  //     const matchingGridRow = globalGridRows.filter((row) => row.flexObject === flextypePath);
  //     if (matchingGridRow.length) {
  //       // if we have this row, replace the placeholder
  //       currentGridRow = matchingGridRow[0];
  //       needToAdd = false;
  //     }

  //     // api will return empty cells if we have no data
  //     // empty cells will not have an aclid

  //     // find all matching cells for this row (New or Fetched)
  //     const newCells = fetchedGridRowCells.filter((gridRowCell: GridRowCell) => gridRowCell.flextypepath === flextypePath && Object.hasOwn(gridRowCell, "aclid"));
  //     currentGridRow.cells.push(...newCells);
  //     if (needToAdd) {
  //       globalGridRows.push(currentGridRow);
  //     }
  //   });

  //   const newGridRows: GridRow[] = [];

  //   globalGridRows.forEach((gridRow) => {
  //     newGridRows.push(gridRow);
  //   });

  //   // sort

  //   globalGridRows = newGridRows.sort(sortGridRows);

  //   setGridRows([...globalGridRows]);
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   if (getAllFlexPaths.length && isFlexPathsLoaded) {
  //     if (selectedFlexBase) {
  //       loadFlexType(selectedFlexBase);
  //     }
  //   }
  // }, [getAllFlexPaths, selectedFlexBase, isFlexPathsLoaded]);

  // const addFlexType = async (flextypeBase: string) => {
  //   if (!flextypeBase) return;
  //   setSelectedFlexBase(flextypeBase);
  //   setIsLoading(true);

  //   if (isFlexPathsLoaded) {
  //     loadFlexType(selectedFlexBase);
  //   }
  // };

  const onFlexBtnClick = async (flexStr: string, selected: boolean) => {
    setIsLoading(true);
    if (!isFlexPathsLoaded) {
      setSelectedFlexBase(flexStr);
      return;
    }
    const url = replaceUrlPipeline(urlUserGroupsOfFlexType, currentEnvName);
    const URI = url.replace(":flexType", flexStr);
    const getResp = await axiosInstance.get(URI);
    const getUGs = getResp.data.data.userGroups;

    let existingUGs = [...allUserGroups];
    if (isAutoSelectUG) {
      existingUGs = existingUGs.map((ug: UserGroup) => {
        const checkIfUGchecked = getUGs.some((existingUg: UserGroup) => existingUg.id === ug.id);
        let finalUg = { ...ug };
        if (checkIfUGchecked) {
          finalUg = {
            ...ug,
            flexType: checkIfUGchecked ? flexStr : null,
            checked: selected,
          };
        }
        return finalUg;
      });
      const checkedUGs = existingUGs.filter((ug: UserGroup) => ug.checked);
      setUserGroupsSelected([...checkedUGs]);
      setAllUserGroups([...existingUGs]);
    }
    if (selected) {
      await fetchAllACLs(existingUGs);
    } else {
      const newRows = [...gridRows].filter((gridRow: GridRow) => gridRow.flexBase !== flexStr);
      setGridRows([...newRows]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (getAllFlexPaths.length && isFlexPathsLoaded) {
      onFlexBtnClick(selectedFlexBase, true);
    }
  }, [getAllFlexPaths, selectedFlexBase, isFlexPathsLoaded]);

  const removeColumnFromTable = (userGroupName: string) => {
    const newUserGroups = [...allUserGroups].map((userGroup: UserGroup) => {
      let finalUserGroup = { ...userGroup };
      if (userGroup.name.toLowerCase() === userGroupName.toLowerCase()) {
        finalUserGroup = {
          ...userGroup,
          checked: false,
        };
      }
      return finalUserGroup;
    });

    setAllUserGroups([...newUserGroups]);

    setUserGroupsSelected([...newUserGroups].filter((ug: UserGroup) => ug.checked === true));

    const filteredData = [...gridRows].map((row) => {
      row.cells = [...row.cells].filter((cell) => cell.usergroupname !== userGroupName);
      // delete row[userGroupName];
      return row;
    });

    setGridRows([...filteredData]);
  };

  const userGroupToggle = (ugs: UserGroup, userGroupEdit: UserGroup[]) => {
    if (ugs.checked) {
      const updatedUGs = [...userGroupEdit].map((ug) => {
        if (ug.id === ugs.id) {
          ug.checked = ugs.checked;
        }
        return ug;
      });
      fetchAllACLs([...updatedUGs]);
    } else {
      removeColumnFromTable(ugs.name);
    }
  };

  const updateAttributeGroupsPermissions = (flextypePath, agarId, permissions) => {
    const ags = { ...attributeGroups };
    const agar = ags[flextypePath].find((ag) => ag.id === agarId);
    if(agar){
      agar.bulkPermissions = permissions;
    }
    setAttributeGroups(ags);
  };

  const singleTabHeight = 43;

  const maxAccordionHeight = 3 * singleTabHeight; // height of title of accordion tab

  const maxAvailableHeight = `calc(100vh - 162px - ${maxAccordionHeight}px)`; // Header + navbar height;

  return (
    <>
      <AppBar />
      {isLoading && (
        <div className="full-screen-loader overlay">
          <ProgressSpinner strokeWidth="5" aria-label="Loading" className="custom-spinner" />
        </div>
      )}
      <div className="p-0">
        <Accordion className="fill-out" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <AccordionTab header="Flex Type and User Group Selection">
            <div style={{ maxHeight: maxAvailableHeight }} className="container-fluid p-0 fill-in-search">
              userGroups: {userGroupsSelected.length} - gridRows: {gridRows.length}
              <div className="row">
                <div className="col-6">
                  <FlexTypes
                    key="flex-type-container"
                    flexBaseProps={flexBases}
                    btnClickHandle={onFlexBtnClick}
                    isAutoSelectUG={isAutoSelectUG}
                    setIsAutoSelectUG={setIsAutoSelectUG}
                    showAttributeGroupsCheckbox={showAttributeGroupsCheckbox}
                    setShowAttributeGroupsCheckbox={setShowAttributeGroupsCheckbox}
                  />
                </div>
                <div className="col-6 right-side">
                  <UserGroups key="user-group-container" userGroups={allUserGroups} triStateVal={triStateVal} setTriStateVal={setTriStateVal} userGroupToggle={userGroupToggle} />
                </div>
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Click to See Data Table">
            <div style={{ maxHeight: maxAvailableHeight, minHeight: maxAvailableHeight }} className="row fill-in m-0">
              <div className="col-12 p-0">
                <MainDataTable key="main-table" tableData={{ userGroups: userGroupsSelected, gridRows, attributeGroups }} removeColumnFromTable={removeColumnFromTable} showAttributeGroupsCheckbox={showAttributeGroupsCheckbox} updateAttributeGroupsPermissions={updateAttributeGroupsPermissions} />
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Data Key and Legend">
            <div style={{ maxHeight: maxAvailableHeight, minHeight: maxAvailableHeight }} className="row fill-in m-0">
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-2" />
                  <div className="col-4 right bold p-2">Data Key</div>
                  <div className="col-4 bold p-2">Meaning</div>
                  <div className="col-2" />
                </div>
                {legend.map((legendItem) => (
                  <div className="row" key={`${legendItem.key}-${legendItem.value}`}>
                    <div className="col-2" />
                    <div className="col-4 right p-2">{legendItem.key}</div>
                    <div className="col-4 p-2">{legendItem.value}</div>
                    <div className="col-2" />
                  </div>
                ))}
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </>
  );
}
