/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from "react";
import { axiosInstance } from "src/services/axios";
import { Toast } from "primereact/toast";
import { attributeGrpsPerms, urlAcls } from "src/util/constants";
import { obsEnvName } from "src/services/observables";
import { replaceUrlPipeline, sortGridRows } from "../../util/functions";
import { CrudPopOver } from "../crud-popover/CrudPopover";
import AttributeGroupModal, { AttributeGroup } from "../attribute-group-modal";

// Interfaces
import { GridRowCellAdd, DeletePayload, GridRow, GridRowCell, GridRowCellUpdate, TableData, UpdatePayload, AgarPerms } from "./data-table-interfaces";

// Styles
import "./data-table.scss";
import { formatFlexTypeForDisplay, formatGridRowCellToTD } from "./data-table-functions";
import { UserGroup } from "../user-groups/userGroupsInterface";
import { AttributHeaderData, AttributeGroupHeaderModal } from "../attribute-group-header-modal/attributeGroupHeaderModal";

export interface MainTableProps {
  tableData: TableData;
  // fetchAllACLs: (val: UserGroup[]) => void;
  removeColumnFromTable: (val: string) => void;
  updateAttributeGroupsPermissions: (val: string, agarId: string, permissions: AgarPerms) => void;
  showAttributeGroupsCheckbox: boolean;
}

export function MainDataTable({ tableData, removeColumnFromTable, showAttributeGroupsCheckbox, updateAttributeGroupsPermissions }: MainTableProps) {
  const toast = useRef<Toast>(null);
  const [flexTypeInput, setFlexTypeInput] = useState("");
  const [attributeGroupInput, setAttributeGroupInput] = useState("");
  const [currentEnvName, setCurrentEnvName] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalCellData, setModalCellData] = useState<GridRowCell>();
  const [isAgarRow, setIsAgarRow] = useState(false);
  const [attributeGroup, setAttributeGroup] = useState<{ id: string; name: string }>();
  const [attributeGroupsToAdd, setAttributeGroupsToAdd] = useState<AttributeGroup[]>();

  // openAttributHeaderModal handle
  const [attributHeaderModal, setAttributHeaderModal] = useState(false);
  const [attributHeaderData, setAttributHeaderData] = useState<AttributHeaderData>();

  // tableData.userGroups = tableData.userGroups.filter((userGroup: UserGroup) => userGroup.checked);
  const [table, setTable] = useState<TableData>(tableData);
  const [open, setOpen] = useState(false);
  const [attributeAction, setAttributeAction] = useState<"ADD" | "REMOVE">();
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    obsEnvName.subscribe((env) => {
      setCurrentEnvName(env);
    });
  });

  const openModal = (evt, col: GridRowCell, row: GridRow) => {
    if (!col) {
      const elementKey = evt.target.getAttribute("data-key");
      const currentUG = elementKey.split("|")[1];
      col = { aclid: undefined, usergroupname: currentUG };
      const currentRow = [...table.gridRows].filter((curRow) => curRow.flexObject === row.flexObject);
      currentRow.forEach((curRow) => {
        const reqCell = curRow.cells.find((cell) => cell.flextypepath === row.flexObject && cell.usergroupname === currentUG);
        if (reqCell) {
          col = { aclid: reqCell.aclid, usergroupname: currentUG };
        }
      });
    }
    setModalCellData({ ...col, bulkPermissions: row?.attributeGroupsData?.bulkPermissions, flextypepath: row.flexObject });
    setIsAgarRow(row.isAGAR);
    setAttributeGroup(row.attributeGroupsData);
    setIsModalOpen(true);
  };

  const handleRemoveIconClick = (flexObject: string) => {
    removeColumnFromTable(flexObject);

    // also remove from current table
    setTable((tableDataCurrent: TableData) => {
      tableDataCurrent.gridRows = tableDataCurrent.gridRows.filter((gridRow: GridRow) => gridRow.flexObject !== flexObject);
      tableDataCurrent.userGroups = tableDataCurrent.userGroups.filter((ug: UserGroup) => ug.name !== flexObject);
      return tableDataCurrent;
    });
  };

  const rowMaker = (filterString?: string, isAttributeGroup: boolean = false) => {
    setTable((tableDataCurrent: TableData) => {
      // now iterate data to build main row set for data table
      tableDataCurrent.gridRows = tableDataCurrent.gridRows.map((gridRowLoop: GridRow) => {
        // Now, all other keys on the row
        const filterBy = filterString?.toLowerCase();
        if (filterString && !isAttributeGroup) {
          gridRowLoop.display = gridRowLoop.flexObject.toLowerCase().indexOf(filterBy) > -1;
        } else if (filterString && isAttributeGroup) {
          gridRowLoop.display = gridRowLoop.attributeGroupsData?.name.toLocaleLowerCase().indexOf(filterBy) > -1;
        } else {
          gridRowLoop.display = true;
        }

        return gridRowLoop;
      });

      return tableDataCurrent;
    });
  };

  const searchHandle = (searchText: string) => {
    setFlexTypeInput(searchText);
    rowMaker(searchText);
  };

  const changeAttributeGroup = (value: string) => {
    setAttributeGroupInput(value);
    rowMaker(value, true);
  };

  const updateCellLocally = (gridRowCellAdd, flextypepath, usergroupname, aclid?: string) => {
    // setTable((tableDataCurrent: TableData) => {
    const tableDataCurrent = { ...table };
    const matchingGridRows: GridRow[] = tableDataCurrent.gridRows.filter((gridRow: GridRow) => gridRow.flexObject === flextypepath && !gridRow.isAGAR);

    if (matchingGridRows.length > 1) {
      console.error("More than one ROW match on add", gridRowCellAdd);
    } else if (matchingGridRows.length === 0) {
      console.error("Zero ROW matches on add", gridRowCellAdd);
    } else {
      const matchingGridRow: GridRow = matchingGridRows[0];
      const matchingGridRowCells: GridRowCell[] = matchingGridRow.cells.filter((grc: GridRowCell) => grc.usergroupname === usergroupname);

      if (matchingGridRowCells.length > 1) {
        console.error("More than one CELL match on add", gridRowCellAdd);
      } else if (matchingGridRowCells.length === 1) {
        if (matchingGridRowCells.length !== 1) {
          console.error("More than one CELL match on update");
        } else {
          const cellObj = { ...gridRowCellAdd, aclid };
          const indexToRemove = matchingGridRow.cells.indexOf(matchingGridRowCells[0]);
          matchingGridRow.cells[indexToRemove] = cellObj;
        }
      } else {
        const gridRowCell: GridRowCell = {
          placeholder: false,
          aclid: "",
          flextypeinternal: "",
          ...gridRowCellAdd,
        };

        // matchingGridRow.cells[0] = gridRowCellAdd;
        matchingGridRow.cells.push(gridRowCell);
      }
    }

    //   return tableDataCurrent;
    // });
    setTable({ ...tableDataCurrent });
  };

  const handleACLAdd = (gridRowCellAdd: GridRowCellAdd) => {
    const { flextypepath, usergroupname } = gridRowCellAdd;

    // update display
    updateCellLocally(gridRowCellAdd, flextypepath, usergroupname);

    // http call
    const URI = replaceUrlPipeline(urlAcls, currentEnvName);
    axiosInstance.post(URI, gridRowCellAdd).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          const aclid = requestCall.data.data.aclid;
          updateCellLocally(gridRowCellAdd, flextypepath, usergroupname, aclid);
          const msg = `${flextypepath} permissions record added for ${usergroupname}`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  const handleACLUpdate = (gridRowCellUpdate: GridRowCellUpdate) => {
    const { flextypepath, usergroupname, allexcept, selected, update } = gridRowCellUpdate;

    // update display
    setTable((tableDataCurrent: TableData) => {
      const matchingGridRows: GridRow[] = tableDataCurrent.gridRows.filter((gridRow: GridRow) => gridRow.flexObject === flextypepath && !gridRow.isAGAR);

      if (matchingGridRows.length > 1) {
        console.error("More than one ROW match on update", gridRowCellUpdate);
      } else if (matchingGridRows.length === 0) {
        console.error("Zero ROW matches on update", gridRowCellUpdate);
      } else {
        const matchingGridRow: GridRow = matchingGridRows[0];

        const matchingGridRowCells: GridRowCell[] = matchingGridRow.cells.filter((gridRowCell: GridRowCell) => gridRowCell.usergroupname === usergroupname);

        if (matchingGridRowCells.length < 1) {
          console.error("Zero CELL matches on update", gridRowCellUpdate);
        } else {
          matchingGridRowCells.forEach((matchingGridRowCell) => {
            matchingGridRowCell.allexcept = update.allexcept;
            matchingGridRowCell.selected = update.selected;
          });
        }
      }

      return tableDataCurrent;
    });

    // http call
    const apiPayload: UpdatePayload = {
      aclid: gridRowCellUpdate.aclid,
      current: { allexcept, selected },
      update,
    };
    const URI = replaceUrlPipeline(urlAcls, currentEnvName);
    axiosInstance.put(URI, apiPayload).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          const msg = `${flextypepath} permissions record added for ${usergroupname}`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  const updateAddAgarTable = (agarData) => {
    const tableDatas = { ...table };
    const currentRow = tableDatas.gridRows.find((row) => row.flexObject === agarData.flextypepath && row?.attributeGroupsData?.id === agarData.agar[0].id && row.isAGAR);
    const obj: any = {
      agarPerms: agarData.agar[0].permissions,
      flextypePath: agarData.flextypepath,
      id: agarData.agar[0].id,
      usergroupname: agarData.usergroupname,
    };
    currentRow.cells = [...currentRow.cells, obj];
    setTable({ ...tableDatas });
  };

  const updateLocalAgarCellAfterAPI = (data) => {
    const tableDatas = { ...table };
    const currentRow = tableDatas.gridRows.find((row) => row.flexObject === data.flextypepath && row?.attributeGroupsData?.id === data.agar[0].id && row.isAGAR);
    const currentAcl = currentRow.cells.find((cell) => cell.usergroupname === data.usergroupname);
    if (currentAcl) {
      currentAcl.aclid = data.aclid;
      currentAcl.flextypeinternal = data.flextypeinternal;
    }
    setTable({ ...tableDatas });
  };

  const updateLocalACLCellAfterAPI = (data) => {
    const tableDatas = { ...table };
    const matchingGridRows: GridRow = tableDatas.gridRows.find((gridRow: GridRow) => gridRow.flexObject === data.flextypepath && !gridRow.isAGAR);
    matchingGridRows.cells.push(data);

    setTable({ ...tableDatas });
  };

  const handleAgarAdd = (agarData) => {
    updateAddAgarTable(agarData);
    const URI = replaceUrlPipeline(urlAcls, currentEnvName);
    axiosInstance.post(URI, agarData).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          updateLocalAgarCellAfterAPI(requestCall.data.data);
          updateLocalACLCellAfterAPI(requestCall.data.data);
          const msg = `${agarData.flextypepath} permissions record added`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  const updateAgarTable = (agarData) => {
    setTable((tableDatas) => {
      // const tableDatas = { ...table };
      const currentRow = tableDatas.gridRows.find((row) => row.flexObject === agarData.flextypepath && row?.attributeGroupsData?.id === agarData.id && row.isAGAR);
      const currentAcl = currentRow.cells.find((cell) => cell.aclid === agarData.aclid && cell.id === agarData.id);
      const rowIndex = currentRow.cells.findIndex((cell) => cell.aclid === agarData.aclid && cell.id === agarData.id);

      if (currentAcl && currentAcl.agarPerms) {
        currentAcl.agarPerms = { ...agarData.updated };
      } else {
        const obj = {
          aclid: agarData.aclid,
          agarPerms: { ...agarData.updated },
          flextypePath: agarData.flextypepath,
          flextypeinternal: currentRow.flextypeinternal,
          id: agarData.id,
          usergroupname: agarData.usergroupname,
        };
        if (currentRow.cells.length) {
          currentRow.cells.splice(rowIndex + 1, 0, obj);
        } else {
          currentRow.cells = [obj];
        }
      }
      return tableDatas;
    });
  };

  const handleAgarUpdate = (agarData) => {
    const currentAcl = table.gridRows.find((row) => row.flexObject === agarData.flextypepath && !row.isAGAR).cells.find((cell) => cell.aclid === agarData.aclid);
    let existingPerms = [];
    if (currentAcl && currentAcl.agar) {
      existingPerms = [...currentAcl.agar.filter((agar) => agar.id !== agarData.id)];
    }
    updateAgarTable(agarData); // update the table locally
    const payload = {
      aclid: agarData.aclid,
      current: { agar: currentAcl.agar },
      update: {
        agar: [
          ...existingPerms,
          {
            name: agarData.name,
            id: agarData.id,
            permissions: agarData.updated,
          },
        ],
      },
    };

    const URI = replaceUrlPipeline(urlAcls, currentEnvName);
    axiosInstance.put(URI, payload).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          const msg = `${agarData.flextypepath} permissions record updated`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  const handleDelete = (gridRowCell: GridRowCell) => {
    const { flextypepath, usergroupname } = gridRowCell;
    // update display
    setTable((tableDataCurrent: TableData) => {
      const matchingGridRows: GridRow[] = tableDataCurrent.gridRows.filter((gridRow: GridRow) => gridRow.flexObject === flextypepath);

      const matchingGridRow: GridRow = matchingGridRows[0];

      const matchingGridRowCells: GridRowCell[] = matchingGridRow.cells.filter((matchingGridRowCell: GridRowCell) => matchingGridRowCell.usergroupname === usergroupname);

      if (!matchingGridRowCells.length) {
        console.error("More than one CELL match on update", gridRowCell);
      } else {
        // delete the record
        const indexToRemove = matchingGridRow.cells.indexOf(matchingGridRowCells[0]);

        if (indexToRemove !== -1) {
          matchingGridRow.cells.splice(indexToRemove, matchingGridRowCells.length);
        } else {
          console.error("Element not found in the array");
        }
      }

      return tableDataCurrent;
    });

    // http call
    const URI = replaceUrlPipeline(urlAcls, currentEnvName);

    const apiPayload: DeletePayload = {
      aclid: gridRowCell.aclid,
    };
    // { flextypepath, usergroupname }
    axiosInstance.delete(URI, { data: apiPayload }).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          const msg = `${flextypepath} permissions record deleted for ${usergroupname}`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  const openAttributHeaderModal = (isAGAR, attr) => {
    if (isAGAR) {
      setAttributHeaderModal(true);
      setAttributHeaderData({
        flexTypePath: attr?.flexObject,
        flextypeinternal: attr?.flextypeinternal,
        attributeGroupsData: {
          ...attr?.attributeGroupsData,
        },
      });
    }
  };

  const closeAttributHeaderModal = () => {
    setAttributHeaderModal(false);
  };

  const saveAttributeData = (attributHeaderDatas, perms, flexttypePath) => {
    const URI = replaceUrlPipeline(attributeGrpsPerms, currentEnvName);

    const apiPayload = [
      {
        flextypeinternal: attributHeaderDatas.flextypeinternal,
        attributeGroups: [
          {
            ...attributHeaderDatas.attributeGroupsData,
            bulkPermissions: { ...perms },
          },
        ],
      },
    ];

    axiosInstance.put(URI, apiPayload).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          const agarId = apiPayload[0].attributeGroups[0].id;
          updateAttributeGroupsPermissions(flexttypePath, agarId, perms);
          const msg = `${attributHeaderDatas.flexTypePath} permissions updated`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  const addAttrPermissions = (attributHeaderDatas, perms, flexttypePath) => {
    const tableDataEx = { ...table };
    const gridRows = tableDataEx.gridRows.map((gridRow) => {
      const obj = { ...gridRow };
      if (gridRow.flexObject === attributHeaderDatas.flexTypePath) {
        if (gridRow.attributeGroupsData && gridRow.attributeGroupsData.name === attributHeaderDatas.attributeGroupsData.name && obj.attributeGroupsData) {
          obj.attributeGroupsData.bulkPermissions = perms;
        }
      }
      return obj;
    });
    tableDataEx.gridRows = [...gridRows];
    delete attributHeaderDatas.attributeGroupsData.isSelected;
    saveAttributeData({ ...attributHeaderDatas }, perms, flexttypePath);
    setTable(tableDataEx);
  };

  //
  //
  //  Main Table
  if (!table) {
    return <h2>Data Loading...</h2>;
  }

  // Header row
  const userGroupColumns = table.userGroups
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((userGroup: UserGroup) => {
      return (
        <th key={userGroup.name} scope="col" className="column">
          <div className="d-flex justify-content-between bold">
            <div>
              <span>{userGroup.name}</span>
            </div>
            <div>
              <span className="btn-icon" onKeyUp={(e) => (e.key === "Space" ? () => handleRemoveIconClick(userGroup.name) : () => { })} onClick={() => handleRemoveIconClick(userGroup.name)}>
                <i className="bi bi-x" />
              </span>
            </div>
          </div>
        </th>
      );
    });

  const newTableData = table.gridRows.filter((gridRow: GridRow) => gridRow.display).sort(sortGridRows);

  const agarRowAddRemoveButtonRenderer = (gridRow) => {
    const keyFlexTypeCol = `flexType-${gridRow.flexObject}`;
    if (showAttributeGroupsCheckbox) {
      if (gridRow.isAGAR) {
        return (
          <button
            onClick={() => {
              setAttributeAction("REMOVE");
              setSelectedRowId(keyFlexTypeCol);
              setOpen(true);
            }}
            type="button"
            className="btn btn-light add-attribute-group-plus"
          >
            <i className="pi pi-minus text-danger" />
          </button>
        );
      } else {
        const currentAttributeGroups = table.attributeGroups[gridRow.flexObject];
        currentAttributeGroups.forEach((grp) => {
          const isSelected = newTableData.some((row) => row.flexObject === gridRow.flexObject && row.display && row?.attributeGroupsData?.id === grp.id);
          grp.isSelected = !!isSelected;
        });
        const nonSelectedOPtions = currentAttributeGroups.filter((grp) => !grp.isSelected);
        if (!nonSelectedOPtions.length) {
          return <div>&nbsp;</div>;
        }
        return (
          <button
            onClick={() => {
              setAttributeGroupsToAdd(currentAttributeGroups);
              setAttributeAction("ADD");
              setSelectedRowId(keyFlexTypeCol);
              setOpen(true);
            }}
            type="button"
            className="btn btn-light add-attribute-group-plus"
          >
            <i className="pi pi-plus" />
          </button>
        );
      }
    } else {
      return <div />;
    }
  };

  // data row
  const arrFlexTypeRows: React.JSX.Element[] = [];

  // format rows for the table
  newTableData.forEach((gridRow: GridRow) => {
    const rowElementsJsx: React.JSX.Element[] = [];
    // const keyFlexTypeCol = `flexType-${gridRow.flexObject}`;

    // Flex Type column
    const flexTypeCol: React.JSX.Element = (
      <td key={gridRow.flexObject} className="flextype-col istCol pb-2">
        <div>
          {agarRowAddRemoveButtonRenderer(gridRow)}
          {/* {showAttributeGroupsCheckbox && (
            <button
              onClick={() => {
                if (gridRow.isAGAR) {
                  setAttributeAction("REMOVE");
                } else {
                  const currentAttributeGroups = table.attributeGroups[gridRow.flexObject];
                  currentAttributeGroups.forEach((grp) => {
                    const isSelected = newTableData.some((row) => row.flexObject === gridRow.flexObject && row.display && row?.attributeGroupsData?.id === grp.id);
                    grp.isSelected = !!isSelected;
                  });
                  setAttributeGroupsToAdd(currentAttributeGroups);
                  setAttributeAction("ADD");
                }
                setSelectedRowId(keyFlexTypeCol);
                setOpen(true);
              }}
              type="button"
              className="btn btn-light add-attribute-group-plus"
            >
              {gridRow.isAGAR ? <i className="pi pi-minus" /> : <i className="pi pi-plus" />}
            </button>
          )} */}
          <div>{formatFlexTypeForDisplay(gridRow.flexObject as string)}</div>
        </div>
      </td>
    );

    if (showAttributeGroupsCheckbox) {
      const attributeGroupCol: React.JSX.Element = (
        <td key={`${gridRow.flexObject}-attr-dialog`} className="istCol pb-2" onClick={() => openAttributHeaderModal(gridRow.isAGAR, gridRow)}>
          <div className="d-flex flex-column">
            <span>{gridRow.isAGAR && gridRow.attributeGroupsData?.name}</span>
            <small className="pos">{gridRow.isAGAR && gridRow.attributeGroupsData?.bulkPermissions?.readableByAll && "Read All"}</small>
            <small className="pos">{gridRow.isAGAR && gridRow.attributeGroupsData?.bulkPermissions?.creatableByAll && "Create All"}</small>
            <small className="pos">{gridRow.isAGAR && gridRow.attributeGroupsData?.bulkPermissions?.updatableByAll && "Update All"}</small>
          </div>
        </td>
      );
      rowElementsJsx.push(flexTypeCol, attributeGroupCol);
    } else {
      rowElementsJsx.push(flexTypeCol);
    }

    // Now Usergroup columns
    table.userGroups
      // Sort alpha by name
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .forEach((userGroup: UserGroup) => {
        const matchingCells = gridRow.cells.find((cell: GridRowCell) => cell.usergroupname === userGroup.name);

        const gridRowCell = {
          placeholder: true,
          // aclid: matchingCells?.aclid || "",
          aclid: "",
          flextype: gridRow.flexObject,
          flextypeinternal: "",
          // flextypepath: matchingCells?.flextypepath || "",
          flextypepath: "",
          usergroupid: userGroup.id,
          usergroupname: userGroup.name,
        };

        const openModel = (event) => {
          openModal.call(this, event, matchingCells, gridRow);
        };

        const rowCellData = matchingCells || gridRowCell;

        const jsx: React.JSX.Element = formatGridRowCellToTD(gridRow, rowCellData, openModel);
        rowElementsJsx.push(jsx);
      });

    arrFlexTypeRows.push(<tr key={`row-${gridRow.flexObject}-${gridRow?.attributeGroupsData?.id}`}>{rowElementsJsx}</tr>);
  });

  const renderAttributeGroupColumn = () => {
    if (showAttributeGroupsCheckbox) {
      return (
        <th scope="col" key="attr-search" className="column">
          <div className="p-1 bold">
            <span>Attribute Group</span>
          </div>
          <div>
            <input type="text" value={attributeGroupInput} className="form-control" placeholder="Type to search..." onChange={(e) => changeAttributeGroup(e.target.value)} />
          </div>
        </th>
      );
    } else {
      return "";
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col" key="search" className="column corner-col">
              <div className="p-1 bold">
                <span>FlexType</span>
              </div>
              <div>
                <input key="search-box" type="text" value={flexTypeInput} className="form-control" placeholder="Type to search..." onChange={(evt) => searchHandle(evt.target.value)} />
              </div>
            </th>
            {renderAttributeGroupColumn()}
            {userGroupColumns}
          </tr>
        </thead>
        <tbody>{arrFlexTypeRows}</tbody>
      </table>
      <CrudPopOver
        isModalOpen={isModalOpen}
        attributeGroup={attributeGroup}
        isAgarRow={isAgarRow}
        modalCellData={modalCellData}
        closeModal={closeModal}
        addRightsHandle={handleACLAdd}
        updateRightsHandle={handleACLUpdate}
        deleteRightsHandle={handleDelete}
        updateAgarRightsHandle={handleAgarUpdate}
        addAgarRightsHandle={handleAgarAdd}
      />
      {open && <AttributeGroupModal selectedRowId={selectedRowId} attributeGroups={attributeGroupsToAdd} attributeAction={attributeAction} open={open} setOpen={setOpen} setTable={setTable} />}
      {attributHeaderModal && <AttributeGroupHeaderModal isModalOpen={attributHeaderModal} closeModal={closeAttributHeaderModal} attributHeaderData={attributHeaderData} addAttrPermissions={addAttrPermissions} />}
    </div>
  );
}
