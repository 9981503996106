/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions, no-template-curly-in-string, react/no-array-index-key, brace-style, import/order, react/jsx-no-useless-fragment */

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import "./style.scss";

const publicConfig = [
  {
    icon: "bi bi-people-fill",
    href: "/",
    id: "Flexes",
    label: "Flexes",
    "data-e2e": "allres",
  },
  {
    icon: "bi bi-person-fill-gear",
    href: "/admin",
    id: "Admin",
    label: "Admin",
    "data-e2e": "admin",
  },
];

export function Navbar() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [selected, setSelected] = useState(0);

  const handleButtonClick = (index: number, url: string) => {
    setSelected(index);

    const env = queryParams.get("env");

    if (env) {
      const queryParamsEnv = new URLSearchParams({ env }).toString();

      const newUrl = `${url}?${queryParamsEnv}`;
      history.push(newUrl);
    } else {
      history.push(url);
    }
  };

  useEffect(() => {
    const currentURL = location.pathname;
    if (currentURL.includes("/admin")) {
      setSelected(1);
    }
  }, []);

  const navConfig = publicConfig;

  return (
    <nav className="custom-nav-bar">
      <div className="dummy-div" />
      {navConfig.map((e, ind) => {
        let buttonClass = "custom-btn-inactive";

        // if the current iter is the selected
        if (selected === ind) {
          buttonClass = "custom-btn-active";
        }

        return (
          <button key={ind} type="submit" className={classNames("custom-nav-btn", buttonClass)} data-toggle="tooltip" data-placement="bottom" title={e.id} onClick={() => handleButtonClick(ind, e.href)}>
            <i className={`${e.icon} fs-2 nav-icon`} />
          </button>
        );
      })}
    </nav>
  );
}
