import React from "react";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { TableData } from "../data-table/data-table-interfaces";
import "./attributeGroupModal.scss";

export type AttributeGroup = { name: string; id: string; isSelected?: boolean };

interface AttributeGroupModalProps {
  open: boolean;
  attributeGroups: AttributeGroup[];
  attributeAction: "ADD" | "REMOVE";
  setTable: React.Dispatch<React.SetStateAction<TableData>>;
  setOpen: (val: boolean) => void;
  selectedRowId?: string;
}

function AttributeGroupModal({ open, selectedRowId, attributeGroups, setOpen, attributeAction, setTable }: AttributeGroupModalProps) {
  const [selectedAttributes, setSelectedAttributes] = React.useState<string[]>([]);

  const addAttributeGroup = () => {
    const flexObj = selectedRowId.split("-")[1];
    setTable((oldtable) => {
      const table = { ...oldtable };
      const currentRows = table.gridRows.filter((row) => row.flexObject === flexObj && row.display);
      const rowIndex = table.gridRows.findIndex((row) => row.flexObject === flexObj && row.display && !row.isAGAR);
      if (rowIndex > -1) {
        const newAgarRows = [];
        selectedAttributes.forEach((item) => {
          const alreadyAdded = currentRows.some((row) => row?.attributeGroupsData?.id === item);
          if (!alreadyAdded) {
            const attributeGroup = attributeGroups.find((attr) => attr.id === item);
            newAgarRows.push({ ...table.gridRows[rowIndex], isAGAR: true, attributeGroupsData: attributeGroup });
          }
        });
        table.gridRows.splice(rowIndex + 1, 0, ...newAgarRows);
      }
      return table;
    });
  };

  const removeAttributeGroup = () => {
    setTable((table) => {
      const rowIndex = table.gridRows.findIndex((row) => {
        return selectedRowId === `flexType-${row.flexObject}`;
      });
      if (rowIndex > -1) {
        // firsthalf + newRow + secondHalf
        const tableRowCopy = [...table.gridRows];
        tableRowCopy.splice(rowIndex, 1);
        return { ...table, gridRows: tableRowCopy };
      }
      return table;
    });
  };

  const selectedAttributeList = () => {
    if (attributeGroups) {
      const collectiveArray = [...attributeGroups.filter((grp) => grp.isSelected).map((grp) => grp.id), ...selectedAttributes];
      return (
        <div className="row mt-3">
          <h4 className="pb-2">Selected Attribute Group:</h4>
          <div>
            {collectiveArray.map((attribute, index) => (
              <React.Fragment key={attribute}>
                <small>{attributeGroups.find((grp) => grp.id === attribute).name}</small>
                {index < collectiveArray.length - 1 && ", "}
              </React.Fragment>
            ))}
          </div>
        </div>
      );
    }
    return "";
  };

  return (
    <Dialog
      style={{ width: "30vw" }}
      className="custom-modal"
      header="Manage Attribute Group"
      visible={open}
      onHide={() => {
        setOpen(false);
      }}
    >
      <div className="attribute-group-div p-3">
        <div className="row d-flex flex-column">
          {selectedAttributeList()}
          {attributeAction === "ADD" ? (
            <MultiSelect
              className="w-100 mt-3"
              optionLabel="label"
              display="chip"
              maxSelectedLabels={3}
              placeholder="Select attributes"
              value={selectedAttributes}
              options={attributeGroups.filter((grp) => !grp.isSelected).map((attr) => ({ label: attr.name, value: attr.id }))}
              onChange={(e) => setSelectedAttributes(e.value)}
            />
          ) : (
            <div className="mt-3">Confirm removing Attribute Group data</div>
          )}
        </div>
        <div className="row buttons">
          <div className="col-12">
            <Button
              className="p-button-success mr-2"
              type="button"
              onClick={() => {
                if (attributeAction === "ADD") {
                  addAttributeGroup();
                } else {
                  removeAttributeGroup();
                }
                setOpen(false);
              }}
            >
              {attributeAction === "ADD" ? "Add" : "Remove"}
              {attributeAction === "ADD" ? <i className="pi pi-plus ms-1" /> : <i className="pi pi-minus ms-1" />}
            </Button>
            <Button className="p-button-info mr-2" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default AttributeGroupModal;
