const adminGroups = ["App.PCX.SecurityMgmtApp.Admin"];

const oktaClientId = "nike.piccpd.pcx-securitymanagementapp";
const oktaScopes = ["openid", "profile", "email"];

const apiHostAddressDev = "pcx-securityapp-api-dev.pes-preprod.nike.com";
const oktaUrl = "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7";

const apiUrl = `https://${apiHostAddressDev}/dev`;
const redirectUri = "implicit/callback";
const postLogoutRedirectUrl = "https://nike-qa.oktapreview.com";

const websocketUrl = "wss://298w6cb77h.execute-api.us-west-2.amazonaws.com/dev";

const useOkta = true;
const nonOktaUser = "test@localhost";

const envList = ["shortcycle", "pipelineA", "pipelineB", "pipelineC"];

const currentEnv = "dev";

export { envList, websocketUrl, oktaClientId, adminGroups, oktaScopes, postLogoutRedirectUrl, redirectUri, useOkta, oktaUrl, apiUrl, currentEnv, nonOktaUser };
