import { combineReducers } from "@reduxjs/toolkit";
import { useDispatch as useReduxDispatch } from "react-redux";
import { type Dispatch } from "redux";
import { actionCreators as adminActionCreators, reducer as adminApi } from "./admin";
import { actionCreators as pipelineActionCreators, reducer as pipelineApi } from "./pipeline";

export const actionCreators = {
  ...adminActionCreators,
  ...pipelineActionCreators,
};

export type Action = ReturnType<(typeof actionCreators)[keyof typeof actionCreators]>;
export const rootReducer = combineReducers({ adminApi, pipelineApi });
export type RootState = ReturnType<typeof rootReducer>;

export const useDispatch: () => Dispatch<Action> = useReduxDispatch;
