import React from "react";
import { GridRow, GridRowCell, CrudPermissions } from "./data-table-interfaces";

export const permissionHasValue = (permissions: CrudPermissions) => {
  if (permissions === undefined) {
    return false;
  }

  if (Object.hasOwn(permissions, "C")) {
    return true;
  }
  if (Object.hasOwn(permissions, "U")) {
    return true;
  }
  if (Object.hasOwn(permissions, "R")) {
    return true;
  }
  if (Object.hasOwn(permissions, "D")) {
    return true;
  }
  if (Object.hasOwn(permissions, "A")) {
    return true;
  }
  return false;
};

export const renderPermissions = (permissions: CrudPermissions, sign: "+" | "-") => {
  // default negative
  let spanClass = "neg";
  let match = 0;

  // Test Positive
  if (sign === "+") {
    spanClass = "pos";
    match = 1;
  }

  let permissionsString = "";

  if (permissions.C !== undefined && permissions.C === match) {
    permissionsString = `${permissionsString}C`;
  }
  if (permissions.R !== undefined && permissions.R === match) {
    permissionsString = `${permissionsString}R`;
  }
  if (permissions.U !== undefined && permissions.U === match) {
    permissionsString = `${permissionsString}U`;
  }
  if (permissions.D !== undefined && permissions.D === match) {
    permissionsString = `${permissionsString}D`;
  }

  // Special case for Full Access
  if (permissions.A !== undefined && permissions.A === match) {
    permissionsString = "Full";
  }

  if (permissionsString) {
    return (
      <span className={spanClass}>
        {sign}
        {permissionsString}
      </span>
    );
  } else {
    return "";
  }
};

// convert flextype with / to spaced out for table display
export const formatFlexTypeForDisplay = (flextype: string) => {
  if (!flextype) {
    return "";
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  let ret: React.JSX.Element = <></>;

  const arrFlextypeStructure = flextype.split("/");

  ret = (
    <>
      {arrFlextypeStructure.length >= 1 && arrFlextypeStructure[0]}
      {arrFlextypeStructure.length >= 2 && (
        <p>
          &nbsp;&nbsp;
          {arrFlextypeStructure[1]}
        </p>
      )}
      {arrFlextypeStructure.length >= 3 && (
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {arrFlextypeStructure[2]}
        </p>
      )}
      {arrFlextypeStructure.length >= 4 && (
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {arrFlextypeStructure[3]}
        </p>
      )}
      {arrFlextypeStructure.length >= 5 && (
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {arrFlextypeStructure[4]}
        </p>
      )}
      {arrFlextypeStructure.length >= 6 && (
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {arrFlextypeStructure[5]}
        </p>
      )}
    </>
  );
  return ret;
};

export const getRowCellID = (gridRowCell: GridRowCell, gridRow): string => {
  return `${gridRowCell.aclid}-${gridRowCell.flextype || gridRowCell.flextypepath}|${gridRowCell.usergroupname}|${gridRow?.attributeGroupsData?.id}`;
};

export const getRowDataKey = (gridRowCell: GridRowCell): string => {
  return `${gridRowCell.flextype || gridRowCell.flextypepath}|${gridRowCell.usergroupname}`;
};

// Data Rows
export const renderCell = (gridRowCell: GridRowCell): string => {
  // debugger;
  const hasSelected = permissionHasValue(gridRowCell.selected);
  const hasAllexcept = permissionHasValue(gridRowCell.allexcept);

  let ret;
  if (hasSelected) {
    ret = (
      <span>
        {ret}
        S:
        {renderPermissions(gridRowCell.selected, "+")}
        {renderPermissions(gridRowCell.selected, "-")}
        <br />
      </span>
    );
  }

  if (hasAllexcept) {
    // header
    ret = (
      <span>
        {ret}
        X:
        {renderPermissions(gridRowCell.allexcept, "+")}
        {renderPermissions(gridRowCell.allexcept, "-")}
      </span>
    );
  }

  // empty rules
  if (ret === "") {
    ret = <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  }
  return ret;
};

export const renderAgarCell = (gridRowCell): string => {
  let ret;
  const perms = [];
  if (gridRowCell?.C) {
    perms.push("C");
  }
  if (gridRowCell?.R) {
    perms.push("R");
  }
  if (gridRowCell?.U) {
    perms.push("U");
  }
  ret = <span className="pos">{perms.join("")}</span>;

  // empty rules
  if (ret === "") {
    ret = <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  }
  return ret;
};

export const formatGridRowCellToTD = (gridRow: GridRow, gridRowCell: GridRowCell, openModal?: (evt) => void): React.JSX.Element => {
  const rowCellKey = getRowCellID(gridRowCell, gridRow);
  const rowDataKey = getRowDataKey(gridRowCell);

  return (
    <td key={rowCellKey} data-key={rowDataKey} onClick={(event) => openModal?.(event)} className="crud-display">
      {!gridRow.isAGAR && renderCell(gridRowCell)}
      {gridRow.isAGAR && gridRowCell.agarPerms && renderAgarCell(gridRowCell.agarPerms)}
    </td>
  );
};
