import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import classNames from "classnames";
import "./flexType.scss";
import { FlexBase, FlexBasePropsInterface } from "./flexTypeInterface";

export function FlexTypes(props: FlexBasePropsInterface) {
  const { flexBaseProps, btnClickHandle, isAutoSelectUG, setIsAutoSelectUG, showAttributeGroupsCheckbox, setShowAttributeGroupsCheckbox } = props;
  const [flexBase, setSetFlexBase] = useState<FlexBase[]>([]);

  useEffect(() => {
    setSetFlexBase([...flexBaseProps]);
  }, [flexBaseProps]);

  const toggleChecked = (userFlexToToggle: FlexBase, ind: number) => {
    const flexBaseEdit = [...flexBase];
    userFlexToToggle.selected = !userFlexToToggle.selected;
    flexBaseEdit[ind] = userFlexToToggle;
    setSetFlexBase([...flexBaseEdit]);
    btnClickHandle(userFlexToToggle.flexobject, userFlexToToggle.selected);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="auto-select-checkbox border-bottom-light">
          <span className="fs-6"> Auto Select User Groups: </span>
          <input className="toggle-checkbox ms-2" type="checkbox" checked={isAutoSelectUG} onChange={() => setIsAutoSelectUG(!isAutoSelectUG)} />
        </div>
        <div className="auto-select-checkbox border-bottom-light d-flex justify-content-end">
          <span className="fs-6 pt-1"> Show Attribute Groups: </span>
          <input className="toggle-checkbox ms-2" type="checkbox" checked={showAttributeGroupsCheckbox} onChange={() => setShowAttributeGroupsCheckbox(!showAttributeGroupsCheckbox)} />
        </div>
      </div>

      {flexBase.map((flexBaseEle, ind) => (
        <Button
          className={classNames("p-button p-button-outlined p-button-secondary p-button-raised", { "btn-selected": flexBaseEle.selected })}
          key={`button-${flexBaseEle.flexobject}`}
          label={flexBaseEle.flexobject}
          onClick={() => toggleChecked(flexBaseEle, ind)}
        />
      ))}
    </>
  );
}
