import { createAction, createReducer } from "@reduxjs/toolkit";
import { RootState } from ".";

export const actionCreators = {
  setToken: createAction<{ token: string }, "SET_TOKEN">("SET_TOKEN"),
  setAdmin: createAction<{ admin: boolean }, "SET_ADMIN">("SET_ADMIN"),
  setEmail: createAction<{ email: string }, "SET_EMAIL">("SET_EMAIL"),
};

interface ReducerState {
  obsOktaToken: string | null;
  obsOktaAdmin: boolean;
  obsOktaEmail: string | null;
}

const initialState: ReducerState = {
  obsOktaToken: null,
  obsOktaAdmin: false,
  obsOktaEmail: null,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actionCreators.setToken, (state, action) => {
    state.obsOktaToken = action.payload.token;
  });

  builder.addCase(actionCreators.setAdmin, (state, action) => {
    state.obsOktaAdmin = action.payload.admin;
  });

  builder.addCase(actionCreators.setEmail, (state, action) => {
    state.obsOktaEmail = action.payload.email;
  });
});

export const admin = (state: RootState) => state.adminApi.obsOktaAdmin;
