import { Environment, PipelineCollections } from "../components/env-list/envListInterface";

const sortEnv = (environment1: Environment, environment2: Environment) => {
  const env1PipelineSuffix = environment1.displayText.split("pipeline")[1];
  const env2PipelineSuffix = environment2.displayText.split("pipeline")[1];

  return env1PipelineSuffix.charCodeAt(0) - env2PipelineSuffix.charCodeAt(0);
};

export const sortEnvAlphabetically = (envList: Environment[]) => {
  const reducedPipelineData: PipelineCollections = { sortablePipelines: [], nonSortablePipelines: [] };
  const envObject = envList.reduce((prev, current) => {
    if (current.displayText.toLowerCase().includes("pipeline")) {
      prev.sortablePipelines.push(current);
    } else {
      prev.nonSortablePipelines.push(current);
    }
    return prev;
  }, reducedPipelineData);

  const sortedEnvs = [...envObject.nonSortablePipelines, ...envObject.sortablePipelines.sort(sortEnv)];

  return sortedEnvs;
};
