import React, { useEffect, useState } from "react";
import { getUrl } from "src/util/axios-rest";
import { obsPipeLines } from "src/services/observables";
import { replaceUrlProcess } from "src/util/functions";
import { pipeLinesLogs } from "src/util/constants";
import { Logs, LogType, LogValue, LogData } from "../action-logs/ActionLogsInterface";
import { AppBar } from "../AppBar";
import { PipelineCard } from "../Pipeline-card/PipelineCard";
import { sortEnvAlphabetically } from "../../util/utils";
import { Environment } from "./envListInterface";
import { CopyToClipboard } from "../../util/CopyToClipboard";
import "./envList.scss";

const logTypesData = {
  ALL: ["REPLENISH_ACL", "EXPORT_ACL", "DELETE_DATA"],
  REPLENISH_ACL: ["REPLENISH_ACL"],
  DELETE_DATA: ["DELETE_DATA"],
};

const formatDate = (ts: string) => {
  const formatOptions = { month: "short", hour: "2-digit", day: "2-digit", minute: "2-digit" } as const;

  return new Date(ts).toLocaleDateString("en-us", formatOptions);
};

function ExecutionKey({ text, children }: { text: string; children: React.ReactNode }) {
  return (
    <span className="d-flex align-items-center execution-key gap-2">
      <CopyToClipboard text={text} /> {children}
    </span>
  );
}

export function EnvList() {
  const [environmentList, setEnvironmentList] = useState<Environment[]>([]);
  const [logs, setLogs] = React.useState<Record<string, Logs[]>>({});
  const mounted = React.useRef(false);
  // const pipelines = useSelector(selectPipelines);

  const [pipelineLoading, setPipelineLoading] = useState(false);

  const transformLogs = React.useCallback((data: LogData[], envList: Environment[]) => {
    const sortLogs = (log1: LogData, log2: LogData) => {
      const log1CreatedTs = new Date(log1.createdAt).getTime();
      const log2CreatedTs = new Date(log2.createdAt).getTime();
      return log2CreatedTs - log1CreatedTs;
    };

    const filterLogsByEnv = (pipeline: string) => {
      return data.filter((log) => log.pipeline === pipeline);
    };

    const formatLogs = (log: LogData) => {
      const { createdAt, executionKey, process, username, step, status } = log;

      const updatedKey = `${executionKey.slice(0, 4)}****${executionKey.slice(-5)}`;

      const keyElement = <ExecutionKey text={executionKey}>{updatedKey}</ExecutionKey>;

      const logData = {
        username,
        executionKey: keyElement,
        status,
        process,
        step,
        createdAt: formatDate(createdAt),
      };

      return logData;
    };

    const finalLogs = envList.reduce((prev, current) => {
      const pipelineName = current.pipeline;

      const transformedLogs = filterLogsByEnv(current.pipeline).sort(sortLogs).map(formatLogs);

      return { ...prev, [pipelineName]: transformedLogs };
    }, {});

    if (mounted.current) {
      setLogs(finalLogs);
    }
  }, []);

  const fetchLogs = React.useCallback(
    async (logType: LogType, envList: Environment[]) => {
      try {
        const logsToFetch = logTypesData[logType];

        const logsPromise = logsToFetch.map((log) => getUrl(replaceUrlProcess(pipeLinesLogs, log)));

        const logResponse = await Promise.allSettled<LogValue>(logsPromise);

        const fulfilledResponse = logResponse.filter((response) => response.status === "fulfilled") as PromiseFulfilledResult<LogValue>[];

        const logsToTransform = fulfilledResponse.map((logData) => logData.value?.data).flat();

        transformLogs(logsToTransform, envList);
      } catch (err) {
        console.error(err);
      }
    },
    [transformLogs],
  );

  useEffect(() => {
    setPipelineLoading(true);

    const pipelineSubscription = obsPipeLines.subscribe((envList) => {
      const sortedEnvs = sortEnvAlphabetically(envList);

      setEnvironmentList([...sortedEnvs]);

      setPipelineLoading(false);
    });
    return () => {
      if (pipelineSubscription) {
        pipelineSubscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (environmentList.length > 0) {
      fetchLogs("ALL", environmentList);
    }
    return () => {
      mounted.current = false;
    };
  }, [fetchLogs, environmentList]);

  const emptyPipelineMessage = <h2>No Pipeline Found</h2>;

  const pipelineContent = environmentList.map((env) => {
    const logsData = { logs: logs[env.pipeline], loading: false };
    return <PipelineCard key={env.displayText} fetchLogsData={(logType) => fetchLogs(logType, environmentList)} logsData={logsData} environment={env} />;
  });

  return (
    <>
      <AppBar />
      <div className="container-lg">
        <div className="d-flex flex-column align-items-center">
          {pipelineLoading && <h2>Loading...</h2>}
          {!pipelineLoading && environmentList.length === 0 && emptyPipelineMessage}
          {!pipelineLoading && pipelineContent}
        </div>
      </div>
    </>
  );
}
